import { Injectable } from '@angular/core'
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http'

import { Observable, of } from 'rxjs'
import { catchError, map, tap} from 'rxjs/operators'

import { Email } from 'src/app/models/email.model'
import { environment } from 'src/environments/environment'

const HOST = environment.HOST
const PATH_EMAIL = environment.PATH_EMAIL

const headers = environment.HttpHeaders
const httpOptions = { headers }

@Injectable({
	providedIn: 'root',
})
export class EmailService {
	constructor(private http: HttpClient) {}

  getDestinatarios(sistema: string, grupo: string): Observable<any> {
    return this.http.get<any>(`${HOST}/${PATH_EMAIL}/mensagens/${sistema}/${grupo}`, httpOptions).pipe(
			tap((_) => console.log(`Response getDestinatarios`)),
			catchError(this.handleError<any>('getDestinatarios', null))
		)
  }

  sendEmail(email: Email): Observable<any> {
		return this.http.post<Email>(`${HOST}/${PATH_EMAIL}/mensagens`, email, httpOptions).pipe(
			tap((_) => console.log(`Response sendEmail`)),
			catchError(this.handleError<any>('sendEmail'))
		)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}
}
