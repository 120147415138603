<div *ngIf="loginDisplay" class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<h4 class="title">Listar grupos</h4>
		<div class="input-group search w-25" style="margin-right: 0">
			<input class="form-control" type="text" placeholder="Pesquisar" [(ngModel)]="listFilter" />
			<div class="input-group-append">
				<button type="button" class="btn-input-customer" arial-label="Close" (click)="cleanFiltered()">
					<em i-bs name="x"></em>
				</button>
			</div>
		</div>
	</div>
	<div *ngIf="!perfilNormativo || perfilTecnico" class="card-header py-4 d-flex justify-content-between align-items-center">
		<div id="buttonCreate" class="buttons">
			<button class="btn btn-customer-info btn-customer-create" ngbTooltip="Criar um grupo" (click)="openModal(modalCreate)">
				<em i-bs name="plus" width="20" height="20"></em>
			</button>
		</div>
	</div>
	<div class="card-body h-auto">
		<div class="d-flex justify-content-center align-items-center py-5" *ngIf="infoMessage.status == 1">
			<strong>{{ infoMessage.message }}</strong>
		</div>
		<div class="d-flex justify-content-center align-items-center py-5" *ngIf="infoMessage.status == 2">
			<div class="spinner-border text-primary" role="status"></div>
		</div>
		<div class="table-responsive" *ngIf="grupos && infoMessage.status == 3">
			<table class="table table-sm table-hover align-middle" summary="Grupos">
				<thead class="table-light">
					<tr>
						<th scope="col" (click)="onSort('nome')">
							Nome <span *ngIf="key == 'nome'">{{ reverse ? '▲' : '▼' }}</span>
						</th>
						<th scope="col">Empresas</th>
						<th scope="col">Processos enviados</th>
						<th scope="col" *ngIf="!perfilNormativo || perfilTecnico" class="text-center align-middle">Editar</th>
						<th scope="col" *ngIf="!perfilNormativo || perfilTecnico" class="text-center align-middle">Apagar</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let grupo of pagefiltered | orderBy : order : reverse; let i = index">
						<td>{{ grupo.nome }}</td>
						<td>
							<select class="form-select form-control border-opacity-50 form-select-sm">
								<div *ngIf="grupo.empresas.length > 0"></div>
								<option *ngFor="let empresa of grupo.empresas">{{ empresa.codigo }} - {{ empresa.nome_completo }}</option>
							</select>
						</td>
						<td>
							<select class="form-select form-control border-opacity-50 form-select-sm">
								<option *ngFor="let processo of grupo.enviarProcessos; let i = index">
									{{ processo.descricao }}
								</option>
							</select>
						</td>
						<td *ngIf="!perfilNormativo || perfilTecnico" class="text-center align-middle">
							<button type="button" class="btn btn-customer-info" (click)="update(grupo, modalUpdate)">
								<em i-bs name="pen-fill"></em>
							</button>
						</td>
						<td *ngIf="!perfilNormativo || perfilTecnico" class="text-center align-middle">
							<button type="button" class="btn btn-customer-danger" (click)="delete(grupo, modalDelete)">
								<em i-bs name="trash-fill"></em>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card-footer-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<ngb-pagination
			class="pagination-customer"
			[collectionSize]="collectionSize"
			[(page)]="page"
			[pageSize]="pageSize"
			(pageChange)="refreshPage()"
		></ngb-pagination>

		<select class="pagination-select-customer" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshPage()">
			<option [ngValue]="15">15 items por página</option>
			<option [ngValue]="20">20 items por página</option>
			<option [ngValue]="25">25 items por página</option>
		</select>
	</div>
</div>

<ng-template #modalCreate let-modal>
	<div class="modal-content modal-lg">
		<div class="modal-header">
			<h4 class="title" id="modal-basic-title">Cadastrar grupo</h4>
			<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
				<em i-bs name="x" width="20" height="20"></em>
			</button>
		</div>
		<div class="modal-body">
			<form class="forms-sample" [formGroup]="createGrupoForm">
				<div class="form-group">
					<input type="text" class="form-control border-opacity-50" formControlName="nome" id="nome" placeholder="Nome do grupo" />
				</div>
				<div class="form-group">
					<label class="form-control-sm text-secondary" for="empresas">Empresas</label>
					<div class="div-customer-empresa">
						<div class="checkbox" *ngFor="let empresa of empresas; let i = index">
							<label class="checkbox-inline input-check" name="empresas">
								<input type="checkbox" name="empresas" (change)="onChangeEmpresas(empresa, $event)" style="margin-right: 0.5rem" />
								{{ empresa.codigo }} - {{ empresa.nome_completo }}
							</label>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="enviarProcessos" class="px-2">Enviar processo</label>
					<div class="div-customer-processo">
						<div class="checkbox" *ngFor="let processo of processos; let i = index">
							<label class="checkbox-inline input-check" name="empresas">
								<input type="checkbox" name="empresas" (change)="onChangeProcessos(processo, $event)" style="margin-right: 0.5rem" />
								{{ processo.descricao }}
							</label>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn-modal-customer-info" (click)="onCreate(createGrupoForm.value)" [disabled]="!createGrupoForm.valid">OK</button>
			<button type="button" class="btn-modal-customer-danger" (click)="modal.dismiss('Cross click')">CANCELAR</button>
		</div>
	</div>
</ng-template>

<ng-template #modalUpdate let-modal>
	<div class="modal-content modal-lg">
		<div class="modal-header">
			<h4 class="title" id="modal-basic-title">Editar grupo</h4>
			<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
				<em i-bs name="x" width="20" height="20"></em>
			</button>
		</div>
		<div class="modal-body">
			<form class="forms-sample" [formGroup]="updateGrupoForm">
				<fieldset disabled>
					<legend style="display: none">Update</legend>
					<div class="form-group" style="margin-bottom: 1rem">
						<input type="text" class="form-control border-opacity-50" formControlName="nome" id="nome" placeholder="Nome do grupo" />
					</div>
				</fieldset>
				<div class="form-group">
					<label for="empresas" class="px-2">Empresas</label>
					<div class="div-customer-empresa">
						<div class="checkbox" *ngFor="let empresa of empresas; let i = index">
							<label class="checkbox-inline input-check" name="empresas">
								<input
									type="checkbox"
									name="empresas"
									[checked]="isCheckedEmpresa(empresa)"
									(change)="onChangeEmpresasUpdate(empresa)"
									style="margin-right: 0.5rem"
								/>
								{{ empresa.codigo }} - {{ empresa.nome_completo }}
							</label>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="enviarProcessos" class="px-2">Enviar processo</label>
					<div class="div-customer-processo">
						<div class="checkbox" *ngFor="let processo of processos; let i = index">
							<label class="checkbox-inline input-check" name="empresas">
								<input
									type="checkbox"
									name="empresas"
									[checked]="isCheckedProcesso(processo)"
									(change)="onChangeProcessosUpdate(processo)"
									style="margin-right: 0.5rem"
								/>
								{{ processo.descricao }}
							</label>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn-modal-customer-info" (click)="onUpdate(updateGrupoForm)" [disabled]="disabledButtonInputValid(updateGrupoForm)">
				OK
			</button>
			<button type="button" class="btn-modal-customer-danger" (click)="onDismiss()">CANCELAR</button>
		</div>
	</div>
</ng-template>

<ng-template #modalDelete let-modal>
	<div class="modal-header">
		<h4 class="title" id="modal-basic-title">Apagar grupo</h4>
		<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<em i-bs name="x" width="20" height="20"></em>
		</button>
	</div>
	<div class="modal-body">
		<fieldset>
			<legend>Deseja realmente excluir esse registro ?</legend>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn-modal-customer-info" (click)="onDelete(deleteGrupoForm)">OK</button>
		<button type="button" class="btn-modal-customer-danger" (click)="modal.dismiss('Cross click')">CANCELAR</button>
	</div>
</ng-template>
