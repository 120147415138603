import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

import { Processo } from 'src/app/models/processo.model'
import { environment } from 'src/environments/environment'

const HOST = environment.HOST
const PATH_PTA = environment.PATH_PTA

const headers = environment.HttpHeaders
const httpOptions = { headers }
@Injectable({
	providedIn: 'root',
})
export class ProcessoService {
	constructor(private http: HttpClient) {}

	getProcessos(): Observable<any> {
		return this.http.get<any>(`${HOST}/${PATH_PTA}/processos`, httpOptions).pipe(
			tap((_) => console.log(`Response getProcessos`)),
			catchError(this.handleError<any>('getProcessos', null))
		)
	}

	getProcessoPorNome(nome: string): Observable<any> {
		return this.http.get<any>(`${HOST}/${PATH_PTA}/processo?nome=${nome}`, httpOptions).pipe(
			tap((_) => console.log(`Response getProcessoPorNome`)),
			catchError(this.handleError<any>('getProcessoPorNome', null))
		)
	}

	create(processo: Processo): Observable<any> {
		return this.http.post<Processo>(`${HOST}/${PATH_PTA}/processos`, processo, httpOptions).pipe(
			tap((_) => console.log(`Response createProcesso`)),
			catchError(this.handleError<any>('createProcesso', null))
		)
	}

	update(processo: Processo) {
		return this.http.put<Processo>(`${HOST}/${PATH_PTA}/processos`, processo, httpOptions).pipe(
			tap((_) => console.log(`Response updateProcesso`)),
			catchError(this.handleError<any>('updateProcesso', null))
		)
	}

	delete(processo: Processo) {
		return this.http.delete<Processo>(`${HOST}/${PATH_PTA}/processos/${processo.id}`, httpOptions).pipe(
			tap((_) => console.log(`Response deleteProcesso`)),
			catchError(this.handleError<Processo>('deleteProcesso'))
		)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}
}
