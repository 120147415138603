import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { ToastrService } from 'ngx-toastr'
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { environment } from 'src/environments/environment'
import { Mensagem } from 'src/app/models/mensagem.model'

import { LoginService } from 'src/app/shared/login.service'
import { MensagemService } from './mensagens.service'

import {
	ClassicEditor,
	Bold,
	Essentials,
	Italic,
	Mention,
	Paragraph,
	Undo,
	Link,
	Table,
	List,
	Font,
	Highlight,
	TodoList,
	FindAndReplace,
	Alignment,
  Indent,
  IndentBlock,
  SpecialCharacters,
  SpecialCharactersEssentials,
  Heading
} from 'ckeditor5'

const msgID = environment.message.id

@Component({
	selector: 'app-mensagens',
	templateUrl: './mensagens.component.html',
	styleUrls: ['./mensagens.component.scss'],
})
export class MensagensComponent implements OnInit {
	title = 'angular'

	public Editor = ClassicEditor
	public config = {
		toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				'heading',
				'findAndReplace',
				'|',
				'fontfamily',
				'fontsize',
				'fontColor',
				'fontBackgroundColor',
				'|',
				'highlight',
				'|',
				'bold',
				'italic',
				'strikethrough',
				'subscript',
				'superscript',
				'code',
				'|',
				'link',
				'uploadImage',
				'blockQuote',
				'codeBlock',
				'|',
				'bulletedList',
				'numberedList',
				'todoList',
				'alignment',
				'outdent',
				'indent',
        '|',
        'specialCharacters'
			],
			shouldNotGroupWhenFull: false,
		},
		plugins: [Heading, Font, Bold, Essentials, Italic, Mention, Paragraph, Undo, Link, Table, List, Highlight, TodoList, FindAndReplace, Alignment, Indent, IndentBlock, SpecialCharacters, SpecialCharactersEssentials],
	}

	mensagem: Mensagem = new Mensagem()
	updateMessageForm: FormGroup
	modalRef: NgbModalRef
	closeResult: string
	loginDisplay = false

	constructor(
		private mensagemService: MensagemService,
		private formBuilder: FormBuilder,
		private modalService: NgbModal,
		private toastrService: ToastrService,
		private router: Router,
		private loginService: LoginService
	) {
		this.loginService.ngOnInit()
		this.loginDisplay = this.loginService.authenticated
	}

	ngOnInit() {
		this.getMensagemPorID(msgID)
		this.updateForm()
	}

	getMensagemPorID(msgID: string) {
		this.mensagemService.getMensagemPorID(msgID).subscribe((res) => {
			if (res.status >= 400) {
				this.showAlertInfo(res.statusText)
			} else {
				this.mensagem = res.mensagem
			}
		})
	}

	updateForm() {
		this.updateMessageForm = this.formBuilder.group({
			id: [''],
			titulo: ['', [Validators.required]],
			texto: ['', [Validators.required]],
		})
	}

	update(mensagem: Mensagem, updateMessageForm) {
		updateMessageForm.patchValue({
			id: mensagem.id,
			titutlo: mensagem.titulo,
			texto: updateMessageForm.value.texto,
		})
		this.onUpdate(updateMessageForm)
	}

	onUpdate(form) {
		let mensagem = JSON.parse(JSON.stringify(form.value))
		this.mensagemService.update(mensagem).subscribe(
			(res) => {
				if (res) {
					this.showSucessUpdate('Registro atualizado com sucesso.')
				}
			},
			(error) => {
				let erro: number = error | error.messsage
				this.showErrorUpdate(erro)
			}
		)
	}

	open(form) {
		this.modalRef = this.modalService.open(form)
		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}

	showSucessUpdate(msg) {
		this.toastrService.success(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/mensagens')
	}

	showErrorUpdate(msg) {
		this.toastrService.error(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/mensagens')
	}

	showAlertInfo(msg) {
		this.toastrService.error(`${msg} - Ocorreu um erro ao fazer a chamada, por favor tente mais tarde!`, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/mensagens')
	}
}
