import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, Observable, of, tap } from 'rxjs'

import { Mensagem } from 'src/app/models/mensagem.model'
import { environment } from 'src/environments/environment'

const HOST = environment.HOST
const PATH_PTA = environment.PATH_PTA

const headers = environment.HttpHeaders
const httpOptions = { headers }

@Injectable({
	providedIn: 'root',
})
export class MensagemService {
  messages: string[] = [];
	constructor(private http: HttpClient) {}

  clear() {
    this.messages = [];
  }

	getMensagemPorID(msgID: string): Observable<any> {
		if (msgID == '' || msgID == null) {
			return of(`${msgID} não localizado`)
		}
		return this.http.get<any>(`${HOST}/${PATH_PTA}/mensagem${'?id='}${msgID}`, httpOptions).pipe(
			tap((_) => console.log(`Response getMensagemPorID`)),
			catchError(this.handleError<any>('getMensagemPorID', null))
		)
	}

	update(mensagem: Mensagem) {
		console.log(mensagem)
		return this.http.put<Mensagem>(`${HOST}/${PATH_PTA}/mensagens/`, JSON.parse(JSON.stringify(mensagem)), httpOptions).pipe(
			tap((_) => console.log(`Response update`)),
			catchError(this.handleError<any>('update', null))
		)
	}
	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}

	// create(mensagem: Mensagem): Observable<any> {
	//   return this.http.post<Mensagem>(`${APIURL}`, mensagem, httpOptions).pipe(
	//     tap(_ => console.log(`Response postSistema`)),
	//     catchError(this.handleError<any>('postSistema', null))
	//   )
	// }
	// delete(ID: string) {
	//   return this.http.delete<Mensagem>(`${APIURL}sistemas/${ID}`, httpOptions).pipe(
	//     tap(_ => console.log(`Response deleteSistema`)),
	//     catchError(this.handleError<Mensagem>('deleteSistema', null))
	//   )
	// }
}
