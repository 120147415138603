import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'adicionarVirgula',
})
export class AdicionarVirgulaPipe implements PipeTransform {
	transform(value: string): string {
		return value && value != '' ? ', ' + value : ''
	}
}
