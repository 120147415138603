<div *ngIf="loginDisplay" class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<h4 class="title">Listar empresas</h4>
		<div class="input-group search w-25" style="margin-right: 0">
			<input class="form-control" type="text" placeholder="Pesquisar" [(ngModel)]="listFilter" />
			<div class="input-group-append">
				<button type="button" class="btn btn-input-customer" arial-label="Close" (click)="cleanFiltered()">
					<em i-bs name="x"></em>
				</button>
			</div>
		</div>
	</div>
	<div *ngIf="!perfilNormativo || perfilTecnico" class="card-header py-4 d-flex justify-content-between align-items-center">
		<div id="buttonCreate" class="buttons">
			<button class="btn btn-customer-info btn-customer-create" ngbTooltip="Criar uma empresa" (click)="openModal(modalCreate)">
				<em i-bs name="plus" width="20" height="20"></em>
			</button>
		</div>
	</div>
	<div class="card-body h-auto">
		<div class="d-flex justify-content-center align-items-center py-5" *ngIf="infoMessage.status == 1">
			<strong>{{ infoMessage.message }}</strong>
		</div>
		<div class="d-flex justify-content-center align-items-center py-5" *ngIf="infoMessage.status == 2">
			<div class="spinner-border text-primary" role="status"></div>
		</div>
		<div class="table-responsive" *ngIf="empresas && infoMessage.status == 3">
			<table class="table table-sm table-hover align-middle" summary="Empresas">
				<thead class="table-light">
					<tr>
						<th scope="col" (click)="onSort('codigo')">
							Código da empresa <span *ngIf="key == 'codigo'">{{ reverse ? '▲' : '▼' }}</span>
						</th>
						<th scope="col" (click)="onSort('nome_completo')">
							Nome Completo <span *ngIf="key == 'nome_completo'">{{ reverse ? '▲' : '▼' }}</span>
						</th>
						<th scope="col" (click)="onSort('tipo')">
							Tipo <span *ngIf="key == 'tipo'">{{ reverse ? '▲' : '▼' }}</span>
						</th>
						<th scope="col" *ngIf="!perfilNormativo || perfilTecnico" class="text-center align-middle">Editar</th>
						<th scope="col" *ngIf="!perfilNormativo || perfilTecnico" class="text-center align-middle">Apagar</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let empresa of pagefiltered | orderBy : order : reverse; let i = index">
						<td>{{ empresa.codigo }}</td>
						<td>{{ empresa.nome_completo }}</td>
						<td>{{ empresa.tipo }}</td>
						<td *ngIf="!perfilNormativo || perfilTecnico" class="text-center align-middle">
							<button type="button" class="btn btn-customer-info" (click)="update(empresa, modalUpdate)">
								<em i-bs name="pen-fill"></em>
							</button>
						</td>
						<td *ngIf="!perfilNormativo || perfilTecnico" class="text-center align-middle">
							<button type="button" class="btn btn-customer-danger" (click)="delete(empresa, modalDelete)">
								<em i-bs name="trash-fill"></em>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card-footer-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<ngb-pagination
			class="pagination-customer"
			[collectionSize]="collectionSize"
			[(page)]="page"
			[pageSize]="pageSize"
			(pageChange)="refreshPage()"
		></ngb-pagination>

		<select class="pagination-select-customer" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshPage()">
			<option [ngValue]="15">15 items por página</option>
			<option [ngValue]="20">20 items por página</option>
			<option [ngValue]="25">25 items por página</option>
		</select>
	</div>
</div>

<ng-template #modalCreate let-modal>
	<div class="modal-header">
		<h4 class="title" id="modal-basic-title">Cadastrar empresa</h4>
		<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<em i-bs name="x" width="20" height="20"></em>
		</button>
	</div>
	<div class="modal-body">
		<form class="forms-sample" [formGroup]="createEmpresaForm">
			<div class="form-group">
				<input type="text" class="form-control border-opacity-50" formControlName="codigo" id="codigo" placeholder="Código da empresa no PTA" />
			</div>
			<div class="form-group">
				<input type="text" class="form-control border-opacity-50" formControlName="nome_completo" id="nome_completo" placeholder="Nome completo" />
			</div>
			<div class="form-group">
				<select class="form-select form-control border-opacity-50" formControlName="tipo" (change)="onChange($event, createEmpresaForm)">
					<option disabled selected>Escolha um Tipo</option>
					<option *ngFor="let tipo of tipoEmpresa" [ngValue]="tipo">
						{{ tipo }}
					</option>
				</select>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn-modal-customer-info" (click)="onCreate(createEmpresaForm)" [disabled]="!createEmpresaForm.valid">OK</button>
		<button type="button" class="btn-modal-customer-danger" (click)="modal.dismiss('Cross click')">CANCELAR</button>
	</div>
</ng-template>

<ng-template #modalUpdate let-modal>
	<div class="modal-header">
		<h4 class="title" id="modal-basic-title">Editar empresa</h4>
		<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<em i-bs name="x" width="20" height="20"></em>
		</button>
	</div>
	<div class="modal-body">
		<form class="forms-sample" [formGroup]="updateEmpresaForm">
			<fieldset disabled>
				<legend style="display: none">Update</legend>
				<div class="form-group">
					<input type="text" class="form-control border-opacity-50" formControlName="codigo" id="codigo" placeholder="Código da empresa" />
				</div>
			</fieldset>
			<div class="form-group">
				<input type="text" class="form-control border-opacity-50" formControlName="nome_completo" id="nome_completo" placeholder="Nome completo" />
			</div>
			<div class="form-group">
				<select class="form-control form-select border-opacity-50" formControlName="tipo" (change)="onChange($event, updateEmpresaForm)">
					<option *ngFor="let tipo of tipoEmpresa" [selected]="tipo == empresa.tipo" [ngValue]="tipo" style="text-transform: uppercase">
						{{ tipo }}
					</option>
				</select>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn-modal-customer-info" (click)="onUpdate(updateEmpresaForm)" [disabled]="!updateEmpresaForm.valid">OK</button>
		<button type="button" class="btn-modal-customer-danger" (click)="modal.dismiss('Cross click')">CANCELAR</button>
	</div>
</ng-template>

<ng-template #modalDelete let-modal>
	<div class="modal-header">
		<h4 class="title" id="modal-basic-title">Apagar empresa</h4>
		<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<em i-bs name="x" width="20" height="20"></em>
		</button>
	</div>
	<div class="modal-body">
		<fieldset>
			<legend>Deseja realmente excluir esse registro ?</legend>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn-modal-customer-info" (click)="onDelete(deleteEmpresaForm)">OK</button>
		<button type="button" class="btn-modal-customer-danger" (click)="modal.dismiss('Cross click')">CANCELAR</button>
	</div>
</ng-template>
