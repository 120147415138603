import { OnInit, Injectable } from '@angular/core'
import { MsalService } from '@azure/msal-angular'

import { environment } from 'src/environments/environment'

const perfilAdmin = environment.perfisUser.pta_administradores
const perfilPatrocInterna = environment.perfisUser.pta_patrocinadoras_internas
const perfilNormativos = environment.perfisUser.pta_normativos
const perfilTecnico = environment.perfisUser.pta_tecnico
const perfilCadastro = environment.perfisUser.pta_cadastro
const perfilArrecadacao = environment.perfisUser.pta_arrecadacao
const perfilEmprestimo = environment.perfisUser.pta_emprestimo
const perfilConsignataria = environment.perfisUser.pta_consignataria
const perfilComunicado = environment.perfisUser.pta_comunicado
const perfilCalculoRetroativo = environment.perfisUser.pta_calculo_retroativo

@Injectable()
export class CheckProfile implements OnInit {
	groups: any = []

	constructor(private msalService: MsalService) {}

	ngOnInit(): void {
		this.groups = this.msalService.instance.getAllAccounts()[0].idTokenClaims!.groups
	}

	//Administradores
	checkedPerfilAdmin(): boolean {
		return this.groups?.includes(perfilAdmin)
	}

	//Normativos
	checkedPerfilNormativo(): boolean {
		return this.groups?.includes(perfilNormativos)
	}

	// Tecnico
	checkedPerfilTecnico() {
		return this.groups?.includes(perfilTecnico)
	}

	//Patrocinadora Interna
	checkedPerfilPatrocInterna(): boolean {
		return this.groups?.includes(perfilPatrocInterna)
	}

	// Cadastro
	checkedPerfilCadastro(): boolean {
		return this.groups?.includes(perfilCadastro)
	}

	// Arrecadação
	checkedPerfilArrecadacao(): boolean {
		return this.groups?.includes(perfilArrecadacao) ? true : false
	}

	// Empréstimo
	checkedPerfilEmprestimo(): boolean {
		return this.groups?.includes(perfilEmprestimo) ? true : false
	}

	//Consignatária
	checkedPerfilConsignataria(): boolean {
		return this.groups?.includes(perfilConsignataria) ? true : false
	}

  //Calculo Retroativo
  checkedPerfilCalculoRetroativo(): boolean {
    return this.groups?.includes(perfilCalculoRetroativo) ? true : false
  }
}
