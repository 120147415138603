import { Component, OnInit } from '@angular/core'
import { MsalService } from '@azure/msal-angular'

import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
	userGroup: any = []
	groups: any = []

	perfis = environment.perfisUser

	loginDisplay = false
	perfilAdmin = false
	perfilPatrocInterna = false
	perfilNormativo = false
	perfilTecnico = false
	perfilCadastro = false
	perfilArrecadacao = false
	perfilEmprestimo = false
	perfilConsignataria = false
  perfilCalculoRetroativo = false
	authenticated: any = false
	userInfo: any = {}

	constructor(private msalService: MsalService) {}

	ngOnInit(): void {
		this.authenticated = this.msalService.instance.getAllAccounts() ? true : false
		this.loginDisplay = this.authenticated
		this.groups = this.msalService.instance.getAllAccounts()[0].idTokenClaims!.groups
		this.recuperGroups()
	}

	recuperGroups() {
		if (this.authenticated) {
			if (this.groups) {
				this.ckeckedPerfil(this.groups)
			}
		}
	}

	ckeckedPerfil(groups) {
		groups.map((group) => {
			if (this.perfis.pta_administradores == group) {
				this.perfilAdmin = true
			} else if (this.perfis.pta_normativos == group) {
				this.perfilNormativo = true
			} else if (this.perfis.pta_tecnico == group) {
				this.perfilTecnico = true
			} else if (this.perfis.pta_patrocinadoras_internas == group) {
				this.perfilPatrocInterna = true
			} else if (this.perfis.pta_emprestimo == group) {
				this.perfilEmprestimo = true
			} else if (this.perfis.pta_cadastro == group) {
				this.perfilCadastro = true
			} else if (this.perfis.pta_arrecadacao == group) {
				this.perfilArrecadacao = true
			} else if (this.perfis.pta_consignataria == group) {
				this.perfilConsignataria = true
			} else if (this.perfis.pta_calculo_retroativo == group) {
        this.perfilCalculoRetroativo = true
      }
		})
	}
}
