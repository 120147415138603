import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core'
import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap'
import { Subject } from 'rxjs'
import { LoaderService } from './loader.service'

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
	providers: [NgbProgressbarConfig],
})
export class LoaderComponent implements AfterViewInit {
	isLoading: Subject<boolean> = new Subject<boolean>()
	constructor(private loaderService: LoaderService, private ref: ChangeDetectorRef, config: NgbProgressbarConfig) {
		config.animated = true
		config.striped = true
	}

	ngAfterViewInit(): void {
		this.isLoading = this.loaderService.isLoading
		this.ref.detectChanges()
	}
}
