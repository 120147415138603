export const environment = {
	production: false,
	descricaoAmbiente: 'Desenvolvimento',

	HOST: 'https://azbrsp1apih001.azure-api.net',
	PATH_PTA: 'pta/v1',
	PATH_BLOB: 'blob/v1',
	PATH_EMAIL: 'email/v1',
	PATH_COSMOS: 'docs-bd/v1/bds',
	PATH_COSMOS_SDK: 'docs-bd/v1/consultas/bds',

	message: {
		id: 'b1103bc5-e87d-4daf-bc8e-b613d504c1ea',
	},
	daySubstract_1: 2,
	daySubstract_2: 4,
	messageSendEmail:
		'Sr(a) <b>{0}</b>,<br>Você acabou de receber o acesso ao <b>novo</b> Portal de Transferência de Arquivos (PTA) da Valia.<br>Se você possuir acesso ao Portal do Participante da Valia, basta acessar o PTA com o mesmo Login (e-mail) e Senha.<br>Caso você não possua acesso ao Portal do Participante da Valia, será necessário você concluir seu cadastro clicando em “Inscreva-se agora” na tela de login.<br>Essas informações estão detalhadas no <b>Guia de Utilização.</b><br><br>Para acessar o PTA basta <a href="https://valia.b2clogin.com/valia.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PTA_singin&client_id=a935d32b-78d6-40c6-aaf3-3ae6cc175bad&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fazapp-pta-publicador-dev-001.azurewebsites.net&scope=openid&response_type=id_token&prompt=login">clicar aqui</a>',
	messageSendEmailCalculoRetroativo:
    'Prezados Srs. <br><br> Informamos que o arquivo <b>{0}</b> de <b>{1}</b> já está disponível para download no PTA. <br><br> Para fazer o download clique <a href="{2}">aqui</a> <br><br> Atenciosamente,<br><br> VALIA <br><br> Obs.:Este é um e-mail de notificação e foi gerado automaticamente. Por favor, não responda esta mensagem.<br><br> AVISO LEGAL "As informações existentes nesta mensagem e nos arquivos anexados são para uso restrito. A utilização, divulgação, cópia ou distribuição dessa mensagem por qualquer pessoa diferente do destinatário é proibida. Se essa mensagem foi recebida por engano, favor excluí-la e informar ao remetente pelo endereço eletrônico acima." <br><br> DISCLAIMER "This email and its attachments may contain privileged and/or confidential information. Use, disclosure, copying or distribution of this message by anyone other than the intended recipient is strictly prohibited. If you have received this email in error, please notify the sender by reply email and destroy all copies of this message."',
	HttpHeaders: {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache',
		'anti-csrf-token': 'csrf_token',
		Pragma: 'no-cache',
		'Ocp-Apim-Subscription-Key': '7ec35465d8f64c3799d44d2005aa79bd',
	},

	msalConfig: {
		tenant: 'valiaweb.net.br',
		tenantId: '10e0a6aa-0447-4d2e-bce8-85279ade47d0',
		clientId: '671b10f0-43a6-475d-a1ef-5828f047ce25',
		redirectUri: 'https://ptaadministrador-dev.valia.com.br/',
		//redirectUri: 'http://localhost:4200',
	},
  authorities: {
		signUpSignIn: {
			authority: 'https://login.microsoftonline.com/valiaweb.net.br',
		},
	},
	authorityDomain: 'valiaweb.net.br',

	perfisUser: {
		pta_administradores: '9a48a910-0a09-47eb-8b5e-80053eeb6579',
		pta_normativos: 'be9fa93a-8d1d-4d15-b169-236193cd765d',
		pta_tecnico: '48fe073d-35f6-46ee-9e06-74f7a45981db',
		pta_patrocinadoras_internas: 'f04e1f46-832e-48eb-9354-6e76d0321491',
		pta_cadastro: '507d9a57-5163-4d92-926a-e61faa3b795f',
		pta_arrecadacao: 'fd5b9915-3ee1-46b9-b944-4fd9798968ed',
		pta_emprestimo: '4fd8cbfd-5fb0-499e-a3e8-470114987947',
		pta_consignataria: '23c3bfd8-4ef8-46d1-9d93-1284cd183230',
		pta_comunicado: 'd5522ac1-a1f0-4f45-bfd3-d334478ef06a',
		pta_calculo_retroativo: '31205c8c-872b-4e23-acd1-1b4f32bf3030',
	},
}
