import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

import { Grupo } from 'src/app/models/grupo.model'
import { environment } from 'src/environments/environment'

const HOST = environment.HOST
const PATH_PTA = environment.PATH_PTA

const headers = environment.HttpHeaders
const httpOptions = { headers }

@Injectable({
	providedIn: 'root',
})
export class GrupoService {
	constructor(private http: HttpClient) {}

	getGrupoPorID(idGrupo: string): Observable<any> {
		return this.http.get<any>(`${HOST}/${PATH_PTA}/grupo/${idGrupo}`, httpOptions).pipe(
			tap((_) => console.log(`Response getProcessos`)),
			catchError(this.handleError<any>('getProcessos', null))
		)
	}

	getGrupos(): Observable<any> {
		return this.http.get<any>(`${HOST}/${PATH_PTA}/grupos`, httpOptions).pipe(
			tap((_) => console.log(`Response getGrupos`)),
			catchError(this.handleError<any>('getGrupos', null))
		)
	}

	create(grupo: Grupo): Observable<any> {
		return this.http.post<Grupo>(`${HOST}/${PATH_PTA}/grupos`, grupo, httpOptions).pipe(
			tap((_) => console.log(`Response create`)),
			catchError(this.handleError<any>('create', null))
		)
	}

	update(grupo: Grupo) {
		return this.http.put<void>(`${HOST}/${PATH_PTA}/grupos`, JSON.parse(JSON.stringify(grupo)), httpOptions).pipe(
			tap((_) => console.log(`Response update`)),
			catchError(this.handleError<any>('update', null))
		)
	}

	delete(grupo: Grupo) {
		return this.http.delete<Grupo>(`${HOST}/${PATH_PTA}/grupos/${grupo.id}`, httpOptions).pipe(
			tap((_) => console.log(`Response delete`)),
			catchError(this.handleError<Grupo>('delete'))
		)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}
}
