import { TipoAtividade } from "./tipoAtividade.model";
import { Usuario } from "./usuario.model";


export class RegistroAtividade {
  id?: string;
  tipo: TipoAtividade;
  nomeArquivo?: string;
  dataHora: Date;
  nomeProcesso?: string;
  usuario?: Usuario;
  nomeGrupo?: string;
  idGrupo?: string;
  mesReferenciaNotificacao?: string;
  mesNotificacao?: string;
  dataNotificacao1?: string;
  dataNotificacao2?: string;
  dataNotificacao3?: string;
  tipoPerfil?: string;
  nomeArquivoEnviado?: string;
  _ts?: number
}