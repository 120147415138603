import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuardService } from './guards/auth.guard.service'

import { CalendarioComponent } from './pages/calendario/calendario.component'
import { EmpresasComponent } from './pages/empresas/empresas.component'
import { GruposComponent } from './pages/grupos/grupos.component'
import { HomeComponent } from './pages/home/home.component'
import { MensagensComponent } from './pages/mensagens/mensagens.component'
import { ProcessosComponent } from './pages/processos/processos.component'
import { UsuariosComponent } from './pages/usuarios/usuarios.component'
import { MsalGuard } from '@azure/msal-angular'
import { UploadComponent } from './pages/upload/upload.component'
import { ArquivosenviadosComponent } from './pages/arquivosenviados/arquivosenviados.component'
import { UploadInternoComponent } from './pages/upload-interno/upload-interno.component'

const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		canActivate: [MsalGuard, AuthGuardService],
	},
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [MsalGuard, AuthGuardService],
	},
	{
		path: 'processos',
		component: ProcessosComponent,
		canActivate: [MsalGuard, AuthGuardService],
	},
	{
		path: 'empresas',
		component: EmpresasComponent,
		canActivate: [MsalGuard, AuthGuardService],
	},
	{
		path: 'grupos',
		component: GruposComponent,
		canActivate: [MsalGuard, AuthGuardService],
	},
	{
		path: 'usuarios',
		component: UsuariosComponent,
		canActivate: [MsalGuard, AuthGuardService],
	},
	{
		path: 'mensagens',
		component: MensagensComponent,
		canActivate: [MsalGuard, AuthGuardService],
	},
	{
		path: 'calendario',
		component: CalendarioComponent,
		canActivate: [MsalGuard, AuthGuardService],
	},
	{
		path: 'upload',
		component: UploadComponent,
		canActivate: [MsalGuard, AuthGuardService],
	},
	{
		path: 'arquivosenviados',
		component: ArquivosenviadosComponent,
		canActivate: [MsalGuard, AuthGuardService],
	},
	{
		path: 'upload/interno',
		component: UploadInternoComponent,
		canActivate: [MsalGuard, AuthGuardService],
	},
]

const isIframe = window !== window.parent && !window.opener

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
