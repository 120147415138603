import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { formatDate } from '@angular/common'

import { ToastrService } from 'ngx-toastr'
import * as moment from 'moment'
import {
	ModalDismissReasons,
	NgbDate,
	NgbDateAdapter,
	NgbModal,
	NgbModalRef,
	NgbDateParserFormatter,
	NgbDatepickerI18n,
	NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap'

import { environment } from 'src/environments/environment'

import { CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n } from 'src/app/utils/calendarioPT.util'
import { CheckProfile } from 'src/app/utils/checkProfile.util'

import { RegistroAtividade } from 'src/app/models/registroAtividade.model'
import { TipoPerfil } from 'src/app/models/tipoPerfil.model'
import { TipoAtividade } from 'src/app/models/tipoAtividade.model'

import { CalendarioService } from './calendario.service'
import { LoginService } from 'src/app/shared/login.service'
import { Usuario } from 'src/app/models/usuario.model'
import { MsalService } from '@azure/msal-angular'
import { UsuarioService } from '../usuarios/usuarios.service'

const daySubs_1 = environment.daySubstract_1
const daySubs_2 = environment.daySubstract_2

@Component({
	selector: 'app-calendario',
	templateUrl: './calendario.component.html',
	styleUrls: ['./calendario.component.scss'],
	providers: [
		I18n,
		{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
		{ provide: NgbDateAdapter, useClass: CustomAdapter },
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
})
export class CalendarioComponent implements OnInit {
	calendario: RegistroAtividade
	calendarios: Array<RegistroAtividade> = []
	selectedAnoReferencia: string
	selectedMesReferencia: string
	selectedTipoPerfil: string
	selectedAnoEdicao: string

	usuario: Usuario

	createCalendarForm: FormGroup
	updateCalendarForm: FormGroup
	deleteCalendarForm: FormGroup

	modalRef: NgbModalRef
	closeResult: string

	disablebutton: boolean = true
	markDisabled

	tiposPerfilFilter: string[] = [] //'Todos', 'Consignatária', 'Patrocinadora'
	anosReferenciaFilter: number[] = []
	calendariosFilter: Array<RegistroAtividade> = []

	tiposPerfil = [TipoPerfil.consignataria, TipoPerfil.patrocinadora]
	mesReferencia = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

	mesNotificacao: string = ''
	infoMessage = { status: 0, message: '' }

	date = new Date()
	currentDay = this.date.getDate()
	currentMonth = this.date.getMonth() + 1
	currentYear = this.date.getFullYear()
	nextYear = this.currentYear + 1
	lastYear = this.currentYear - 1

	dezCurrentYear = `12/${this.currentYear}`
	janCurrentYear = `01/${this.currentYear}`
	fevCurrentYear = `02/${this.currentYear}`
	dezNextYear = `12/${this.nextYear}`
	janNextYear = `01/${this.nextYear}`
	fevNextYear = `02/${this.nextYear}`

	showWeekNumbers = false

	model2: string
	loginDisplay = false

	perfilTecnico = false
	perfilNormativo = false

	constructor(
		private calendarioService: CalendarioService,
		private dateAdapter: NgbDateAdapter<string>,
		private modalService: NgbModal,
		private toastrService: ToastrService,
		private router: Router,
		private formBuilder: FormBuilder,
		private loginService: LoginService,
		private checked: CheckProfile,
		private msalService: MsalService,
		private usuarioService: UsuarioService
	) {}

	ngOnInit(): void {
		this.getTipoPerfil()
		this.getAnoReferencia()
		this.getCalendarios(this.selectedAnoReferencia, this.selectedTipoPerfil)
		this.createForm()
		this.updateForm()
		this.deleteForm()
		this.getEmailUser()

		this.loginService.ngOnInit()
		this.loginDisplay = this.loginService.authenticated

		this.checked.ngOnInit()
		this.perfilTecnico = this.checked.checkedPerfilTecnico()
		this.perfilNormativo = this.checked.checkedPerfilNormativo()
	}

	today(comp: any): void {
		comp.toggle()
		comp.navigateTo({
			year: +this.selectedAnoReferencia,
			month: +this.selectedMesReferencia,
		})
	}

	adicionaZero(numero: number): string {
		return numero < 10 ? `0${numero}` : `${numero}`
	}

	getEmailUser() {
		let usuario: any
		const profile = this.msalService.instance.getAllAccounts()
		if (profile) {
			usuario = profile[0].username
		}
		this.usuarioService.getEmailUser(usuario).subscribe((res) => {
			this.usuario = res.usuario
		})
	}

	getTipoPerfil(): void {
		this.tiposPerfilFilter = ['Todos', TipoPerfil.consignataria, TipoPerfil.patrocinadora]
		this.selectedTipoPerfil = 'Todos'
	}

	getAnoReferencia(): void {
		const startYear = this.currentYear
		const endYear = Number(this.nextYear)

		this.anosReferenciaFilter = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i)

		this.selectedAnoReferencia = this.selectedAnoEdicao ?? this.currentYear.toString()
	}

	getCalendarios(anoReferencia: string, tipoPerfil: string): void {
		this.infoMessage.status = 2

		this.calendarioService.getEnvioNotificacao(anoReferencia).subscribe((res) => {
			if (res.status <= 400) {
				this.showMessage(res.status, res.statusText, 'read')
			} else {
				this.infoMessage.status = 3
				this.calendarios = this.completarCalendario(res.Documents, tipoPerfil)
				this.calendariosFilter = this.calendarios
			}
		})
	}

	completarCalendario(calendario: RegistroAtividade[], perfil: string): RegistroAtividade[] {
		const tiposPerfilFilterComplete: string[] = []
		const mesReferenciaNotificacao = this.mesReferencia.map((mes) => `${mes}/${this.selectedAnoReferencia}`)

		// Determina quais perfis devem ser incluídos
		switch (perfil) {
			case 'Todos':
				tiposPerfilFilterComplete.push(TipoPerfil.consignataria, TipoPerfil.patrocinadora)
				break
			case TipoPerfil.consignataria:
				tiposPerfilFilterComplete.push(TipoPerfil.consignataria)
				break
			case TipoPerfil.patrocinadora:
				tiposPerfilFilterComplete.push(TipoPerfil.patrocinadora)
				break
			default:
				return calendario // Se o perfil não corresponder a nenhum caso, retorna o calendário inalterado
		}

		// Filtra o calendário com base no perfil
		if (perfil !== 'Todos') {
			calendario = calendario.filter((item) => item.tipoPerfil === perfil)
		}

		// Completa o calendário para todos os meses e perfis necessários
		mesReferenciaNotificacao.forEach((mesString) => {
			tiposPerfilFilterComplete.forEach((itemPerfil) => {
				if (!calendario.some(({ mesReferenciaNotificacao, tipoPerfil }) => mesReferenciaNotificacao === mesString && tipoPerfil === itemPerfil)) {
					const objCalendario = new RegistroAtividade()
					objCalendario.mesReferenciaNotificacao = mesString
					objCalendario.tipoPerfil = itemPerfil

					calendario.push(objCalendario)
				}
			})
		})

		// Ordena o calendário por mês de referência
		calendario.sort((a, b) => a.mesReferenciaNotificacao!.localeCompare(b.mesReferenciaNotificacao!))

		return calendario
	}

	private atualizarFiltros(anoReferencia: string, tipoPerfil: string): void {
		this.selectedAnoReferencia = anoReferencia
		this.selectedTipoPerfil = tipoPerfil
		this.getCalendarios(this.selectedAnoReferencia, this.selectedTipoPerfil)
	}

	onChangeAnoReferencia(form: string): void {
		this.atualizarFiltros(form, this.selectedTipoPerfil)
	}

	onChangeTipoPerfil(form: string): void {
		this.atualizarFiltros(this.selectedAnoReferencia, form)
	}

	onChange(event, form) {
		if (event.target.value) form.tipo = event.target.value
	}

	rulesDisabledCreate(calendario) {
		if (calendario.dataNotificacao1) {
			return true
		}
		const dataAtual = new Date()
		const anoAtual = dataAtual.getFullYear()
		const mesAtual = this.adicionaZero(dataAtual.getMonth() + 1)
		const dataFormatadaAtual = new Date(`${anoAtual}-${mesAtual}-01`)

		const [mes, ano] = (calendario.mesReferenciaNotificacao || '').split('/').map(Number)
		const dataReferenciaNotificacaoDate = new Date(ano, mes - 1)

		return dataFormatadaAtual > dataReferenciaNotificacaoDate
	}

	rulesDisabledUpdate(calendario: { tipoPerfil?: string; dataNotificacao1?: string; mesNotificacao?: string }): boolean {
		const { tipoPerfil, dataNotificacao1, mesNotificacao } = calendario

		// Desabilita o botão se o perfil for 'Patrocinadora'
		if (tipoPerfil === 'Patrocinadora') {
			return true
		}

		// Desabilita o botão se o perfil for 'Consignatária' e dataNotificacao1 for nula
		if (tipoPerfil === 'Consignatária' && !dataNotificacao1) {
			return true
		}

		// Se o perfil for 'Consignatária', dataNotificacao1 e mesNotificacao não forem nulos
		if (tipoPerfil === 'Consignatária' && dataNotificacao1 && mesNotificacao) {
			// Formata e compara a data de notificação
			const [dia, mes, ano] = (dataNotificacao1 + '/' + mesNotificacao).split('/').map(Number)
			const dataNotificacaoFormatada = new Date(ano, mes - 1, dia)
			const dataAtual = new Date()

			return dataAtual > dataNotificacaoFormatada
		}

		// Caso contrário, habilita o botão
		return false
	}

	rulesDisableDelete(calendario: { dataNotificacao1?: string; mesReferenciaNotificacao?: string }): boolean {
		// Obtém e formata a data atual
		const dataAtual = new Date()
		const dataFormatadaAtual = `${this.adicionaZero(dataAtual.getMonth() + 1)}/${dataAtual.getFullYear()}`

		// Obtém a data de referência da notificação
		const dataReferenciaNotificacao = calendario.mesReferenciaNotificacao || ''

		// Compara as datas e verifica a condição de notificação
		return dataFormatadaAtual >= dataReferenciaNotificacao || !calendario.dataNotificacao1
	}

	subtractDays(qtdDaysRemove: number, dateToRemove: any): number {
		// Converta a data para um objeto moment
		let date = moment(dateToRemove)
		const initialMonth = date.format('MM')

		// Remova dias úteis, excluindo sábados e domingos
		while (qtdDaysRemove > 0) {
			date.subtract(1, 'day')

			if (date.day() !== 6 && date.day() !== 0) {
				qtdDaysRemove--
			}
		}

		// Obtenha o dia do mês resultante
		const finalDay = date.date()

		// Se o mês inicial for diferente do mês atual, o dia final deve ser 0
		return initialMonth !== date.format('MM') ? 0 : finalDay
	}

	calculateNotificationDays(form, dataSelecionada) {
		const { year, month, day } = dataSelecionada._model
		const data = moment(new Date(year, month - 1, day))

		const dataSubstract_5 = this.subtractDays(daySubs_1, data)
		const dataSubstract_10 = this.subtractDays(daySubs_2, data)

		const someDaySubs = daySubs_1 + daySubs_2

		let datas = ''
		const diaLimite = day
		this.mesNotificacao = `${('0' + month).slice(-2)}/${year}`

		if (form.value.tipoPerfil === TipoPerfil.patrocinadora) {
			const today = moment().startOf('day').add(1, 'day')
			if (dataSubstract_5 < today.date() || dataSubstract_10 < today.date()) {
				this.showMessage(200, `Data calculada, o dia limite deve ser no minímo dia ${day + someDaySubs}!`, 'info')
				return
			}
			datas = [dataSubstract_10, dataSubstract_5, diaLimite].filter((dia) => dia !== 0).join(', ')
		} else if (form.value.tipoPerfil === TipoPerfil.consignataria) {
			datas = diaLimite.toString()
		}

		this.createCalendarForm.patchValue({
			datasEnvio: datas,
			mesNotificacao: this.mesNotificacao,
		})

		this.updateCalendarForm.patchValue({
			dataNotificacao1: datas,
			mesNotificacao: this.mesNotificacao,
		})

		this.disablebutton = false
	}

	create(calendario: RegistroAtividade, form: any): void {
		const [mes, ano] = calendario.mesReferenciaNotificacao!.split('/').map(Number)
		this.selectedMesReferencia = mes.toString() // Converte o número para string

		// Converte a data selecionada para o modelo apropriado
		const initialDate = new NgbDate(ano, mes, 1)
		this.dateAdapter.toModel(initialDate)

		// Atualiza as datas desativadas e abre o modal
		this.updateDisabledDates()
		this.openModal(form)

		// Atualiza o formulário com os dados do calendário
		this.createCalendarForm.patchValue({
			id: calendario.id,
			usuario: this.usuario.nome,
			mesReferenciaNotificacao: calendario.mesReferenciaNotificacao,
			mesNotificacao: calendario.mesNotificacao,
			tipoPerfil: calendario.tipoPerfil,
			diaLimite: calendario.dataNotificacao1,
			datasEnvio: [calendario.dataNotificacao1, calendario.dataNotificacao2, calendario.dataNotificacao3].filter(Boolean).join(' '),
		})
	}

	createForm(): void {
		this.createCalendarForm = this.formBuilder.group({
			id: [''],
			mesReferenciaNotificacao: [''],
			mesNotificacao: [''],
			usuario: [''],
			tipoPerfil: [''],
			diaLimite: ['', Validators.required],
			datasEnvio: [''],
		})
	}

	onCreate(): void {
		const formValues = this.createCalendarForm.value
		console.log(formValues, 'formulario')
		const diasEnvio = formValues.datasEnvio.split(',').map((d) => d.trim())

		// Cria um novo registro de atividade
		const registro: any = {
			tipo: TipoAtividade.envioNotificacao,
			dataHora: new Date(),
			usuario: this.usuario.nome,
			mesReferenciaNotificacao: formValues.mesReferenciaNotificacao,
			tipoPerfil: formValues.tipoPerfil,
			mesNotificacao: formValues.tipoPerfil === 'Consignatária' ? formValues.mesNotificacao.trim() : formValues.mesReferenciaNotificacao,
			dataNotificacao1: diasEnvio[0] || '',
			dataNotificacao2: diasEnvio[1] || '',
			dataNotificacao3: diasEnvio[2] || '',
		}

		//Envia o registro para o serviço
		this.calendarioService.createEnvioNotificacao(registro).subscribe(
			(res) => this.showMessage(res.status, 'Dias de envio notificação definido com sucesso.', 'create'),
			(error) => this.showMessage(error.status, error.message || 'Erro desconhecido', 'create')
		)

		// Atualiza o ano selecionado para edição
		this.selectedAnoEdicao = formValues.mesReferenciaNotificacao.slice(3, 7)
	}

	update(calendario: RegistroAtividade, form) {
		console.log(calendario, form, 'update')
		this.selectedMesReferencia = calendario.mesReferenciaNotificacao!.split('/')[0] || ''

		const date = new NgbDate(Number(this.selectedAnoReferencia), Number(this.selectedMesReferencia), 1)
		this.dateAdapter.toModel(date)

		this.openModal(form)
		this.updateCalendarForm.patchValue({
			id: calendario.id,
			tipo: 'EnvioNotificacao',
			dataHora: new Date(),
			usuario: this.usuario.nome,
			mesReferenciaNotificacao: calendario.mesReferenciaNotificacao,
			mesNotificacao: calendario.mesNotificacao,
			tipoPerfil: calendario.tipoPerfil,
			dataNotificacao1: calendario.dataNotificacao1,
		})
	}

	updateForm() {
		this.updateCalendarForm = this.formBuilder.group({
			id: [''],
			tipo: [''],
			dataHora: [''],
			usuario: [''],
			mesReferenciaNotificacao: [{ disabled: true }],
			dataNotificacao1: [''],
			mesNotificacao: [''],
			tipoPerfil: [''],
		})
	}

	onUpdate() {
		this.onChange(event, this.updateCalendarForm)
		const calendar = { ...this.updateCalendarForm.value }
		this.calendarioService.update(calendar).subscribe(
			(res) => this.showMessage(res.status, 'Registro atualizado com sucesso.', 'update'),
			(error) => this.showMessage(error.status, error || error.messsage, 'update')
		)
	}

	updateDisabledDates() {
		this.markDisabled = (date: NgbDate, current: { month: number }) =>
			new Date(date.year, date.month - 1, date.day).getDay() === 6 || new Date(date.year, date.month - 1, date.day).getDay() === 0
	}

	private isSpecialMonth(mesReferencia: string): boolean {
		const specialMonths = [this.janCurrentYear, this.janNextYear, this.fevCurrentYear, this.fevNextYear, this.dezCurrentYear, this.dezNextYear]
		return specialMonths.includes(mesReferencia)
	}

	navigation(mesReferencia: string, tipoPerfil: string): string {
		if (tipoPerfil === 'Consignatária' && this.isSpecialMonth(mesReferencia)) {
			return 'arrow'
		}
		return 'none'
	}

	outsideDays(mesReferencia: string, tipoPerfil: string): string {
		if (tipoPerfil === 'Consignatária' && this.isSpecialMonth(mesReferencia)) {
			return 'visible'
		}
		return 'hidden'
	}

	verificationMonthCurrentYearMinDate(mesReferenciaNotificacao, mesReferenciaCalendar) {
		const dezCurrentYear = `12/${this.currentYear}`
		const janCurrentYear = `01/${this.currentYear}`
		const fevCurrentYear = `02/${this.currentYear}`

		switch (mesReferenciaNotificacao) {
			case dezCurrentYear:
				return {
					year: this.currentYear,
					month: 11,
					day: 1,
				}

			case janCurrentYear:
				return {
					year: this.lastYear,
					month: 12,
					day: 1,
				}

			case fevCurrentYear:
				return {
					year: this.currentYear,
					month: 1,
					day: 1,
				}

			default:
				return {
					year: this.currentYear,
					month: mesReferenciaCalendar,
					day: 1,
				}
		}
	}

	verificationMonthNextYearMinDate(mesReferenciaNotificacao, mesReferenciaCalendar) {
		const dezNextYear = `12/${this.nextYear}`
		const janNextYear = `01/${this.nextYear}`
		const fevNextYear = `02/${this.nextYear}`

		switch (mesReferenciaNotificacao) {
			case dezNextYear:
				return {
					year: this.nextYear,
					month: 11,
					day: 1,
				}

			case janNextYear:
				return {
					year: this.currentYear,
					month: 12,
					day: 1,
				}

			case fevNextYear:
				return {
					year: this.nextYear,
					month: 1,
					day: 1,
				}

			default:
				return {
					year: this.nextYear,
					month: mesReferenciaCalendar,
					day: 1,
				}
		}
	}

	verificationMonthCurrentYearMaxDate(mesReferencia) {
		const janCurrentYear = `01/${this.currentYear}`
		const fevCurrentYear = `02/${this.currentYear}`
		const dezCurrentYear = `12/${this.currentYear}`

		switch (mesReferencia) {
			case janCurrentYear:
				return {
					year: this.currentYear,
					month: 1,
					day: 31,
				}
			case fevCurrentYear:
				return {
					year: this.currentYear,
					month: 2,
					day: 28,
				}
			case dezCurrentYear:
				return {
					year: this.currentYear,
					month: 12,
					day: 31,
				}
			default:
				return {
					year: this.currentYear,
					month: 12,
					day: 31,
				}
		}
	}

	verificationMonthNextYearMaxDate(mesReferencia) {
		const janNextYear = `01/${this.nextYear}`
		const fevNextYear = `02/${this.nextYear}`
		const dezNextYear = `12/${this.nextYear}`

		switch (mesReferencia) {
			case janNextYear:
				return {
					year: this.nextYear,
					month: 1,
					day: 31,
				}

			case fevNextYear:
				return {
					year: this.nextYear,
					month: 2,
					day: 28,
				}

			case dezNextYear:
				return {
					year: this.nextYear,
					month: 12,
					day: 31,
				}

			default:
				return {
					year: this.nextYear,
					month: 12,
					day: 31,
				}
		}
	}

	minDate(mesReferencia, tipoPerfil) {
		var mes = mesReferencia.split('/')[0]

		const mesReferenciaCalendar = mes % 10 === 0 ? mes / 10 : mes

		const mesVerificacao = `${this.adicionaZero(this.currentMonth)}/${this.currentYear}`

		if (parseInt(this.selectedAnoReferencia) === this.currentYear) {
			if (mesReferencia == mesVerificacao) {
				return {
					year: this.currentYear,
					month: this.currentMonth,
					day: this.currentDay + 1,
				}
			} else {
				if (tipoPerfil == 'Consignatária') {
					return this.verificationMonthCurrentYearMinDate(mesReferencia, mesReferenciaCalendar)
				} else {
					return {
						year: this.currentYear,
						month: mesReferenciaCalendar,
						day: 1,
					}
				}
			}
		} else if (parseInt(this.selectedAnoReferencia) === this.nextYear) {
			if (tipoPerfil == 'Consignatária') {
				return this.verificationMonthNextYearMinDate(mesReferencia, mesReferenciaCalendar)
			} else {
				return {
					year: this.nextYear,
					month: mesReferenciaCalendar,
					day: 1,
				}
			}
		} else {
			return {
				year: this.currentYear,
				month: 1,
				day: 1,
			}
		}
	}

	maxDate(mesReferencia) {
		if (parseInt(this.selectedAnoReferencia) === this.currentYear) {
			return this.verificationMonthCurrentYearMaxDate(mesReferencia)
		} else if (parseInt(this.selectedAnoReferencia) === this.nextYear) {
			return this.verificationMonthNextYearMaxDate(mesReferencia)
		} else {
			return {
				year: this.currentYear,
				month: 12,
				day: 31,
			}
		}
	}

	delete(calendario, form) {
		this.openModal(form)
		this.deleteCalendarForm.patchValue({
			id: calendario.id,
			tipo: 'EnvioNotificacao',
			dataHora: calendario.dataHora,
			mesReferenciaNotificacao: calendario.mesReferenciaNotificacao,
			tipoPerfil: calendario.tipoPerfil,
			dataNotificacao1: calendario.dataNotificacao1,
			mesNotificacao: calendario.mesNotificacao,
		})
		this.calendario = calendario
	}

	deleteForm() {
		this.deleteCalendarForm = this.formBuilder.group({
			id: [{ value: '', disabled: true }],
			mesReferenciaNotificacao: [{ value: '', disabled: true }],
			mesNotificacao: [{ value: '', disabled: true }],
			tipoPerfil: [{ value: '', disabled: true }],
			diaLimite: [{ value: '', disabled: true }],
			datasEnvio: [{ value: '', disabled: true }],
		})
	}

	onDelete(form) {
		this.calendario = JSON.parse(JSON.stringify(form.value))
		this.calendarioService.delete(this.calendario).subscribe(
			(res) => this.showMessage(200, 'Registro apagado com sucesso.', 'delete'),
			(error) => this.showMessage(error.status, error || error.messsage, 'delete')
		)
	}

	openModal(form) {
		this.disablebutton = true

		this.modalRef = this.modalService.open(form)
		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}

	showMessage(statusCode: number, msg: any, action: string) {
		const toastrActions: any = {
			create: this.toastrService.success,
			update: this.toastrService.success,
			delete: this.toastrService.success,
			read: this.toastrService.success,
			info: this.toastrService.info,
		}

		if (statusCode >= 400) {
			this.handleError(msg)
		} else {
			const toastrAction = toastrActions[action]
			if (toastrAction) {
				toastrAction.call(this.toastrService, msg, 'PTA - Portal de Transferência de Arquivos')
			} else {
				this.toastrService.error(msg, 'PTA - Portal de Transferência de Arquivos')
			}
			this.closeAndRefresh()
		}
	}

	handleError(msg: any) {
		this.toastrService.error(`${msg} - Ocorreu um erro ao fazer a chamada, por favor tente mais tarde!`, 'PTA - Portal de Transferência de Arquivos')
		this.closeAndRefresh()
	}

	private closeAndRefresh() {
		this.modalRef.close()
		this.router.navigateByUrl('/calendario')
		this.getTipoPerfil()
		this.getAnoReferencia()
		this.getCalendarios(this.selectedAnoReferencia, this.selectedTipoPerfil)
	}
}
