<div class="container-fluid">
	<div *ngIf="loginDisplay" class="row flex-nowrap">
		<div id="sidebar" class="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
			<app-sidebar></app-sidebar>
		</div>
		<div id="content" class="col m-0 p-0">
			<app-navbar></app-navbar>
			<app-content></app-content>
			<app-footer class="d-flex justify-content-center"></app-footer>
		</div>
	</div>
</div>
