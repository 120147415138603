export class Email {
  destinatarios: Array<string>;
  assunto: string;
  corpo: string;
}

export class EmailArquivo {
  destinatarios: Array<string[]>
  assunto: string;
  corpo: string;
}