import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'

import { MsalService } from '@azure/msal-angular'
import { AccountInfo } from '@azure/msal-browser'

import { RegistroAtividade } from 'src/app/models/registroAtividade.model'
import { Usuario } from 'src/app/models/usuario.model'

import { UsuarioService } from '../usuarios/usuarios.service'
import { ArquivosenviadosService } from './arquivosenviados.service'
import { LoginService } from 'src/app/shared/login.service'
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router'
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-arquivosenviados',
	templateUrl: './arquivosenviados.component.html',
	styleUrls: ['./arquivosenviados.component.scss'],
})
export class ArquivosenviadosComponent implements OnInit {
	registroAtividades: Array<RegistroAtividade> = []
	registroAtividade: RegistroAtividade
	dataSaved: boolean = false
	usuario: Usuario

	infoMessage = { status: 0, message: '' }

	modalRef: NgbModalRef

	// search
	_listFilter: string
	filtered: Array<RegistroAtividade> = []
	filter = new FormControl('')

	// paginação
	page = 1
	pageSize = 100
	collectionSize: number
	pagefiltered: Array<RegistroAtividade> = []
	order: string = 'dataHora'

	loginDisplay = false
	profile: AccountInfo[]

	constructor(
		private usuarioService: UsuarioService,
		private arquivosEnviadosService: ArquivosenviadosService,
		private loginService: LoginService,
		private msalService: MsalService,
		private toastrService: ToastrService,
		private router: Router
	) {
		this.loginService.ngOnInit()
		this.loginDisplay = this.loginService.authenticated
	}

	ngOnInit(): void {
		this.profile = this.msalService.instance.getAllAccounts()
		this.getUserEmail()
	}

	toAddZero(numero) {
		if (numero <= 9) return '0' + numero
		else return numero
	}

	get listFilter(): string {
		return this._listFilter
	}

	set listFilter(value: string) {
		this.filtered = this.registroAtividades
		this._listFilter = value
		this.filtered = this.listFilter ? this.onFilter(this.listFilter) : this.registroAtividades
		this.collectionSize = this.filtered.length
		this.pagefiltered = this.filtered.sort((a, b) => (a._ts! < b._ts! ? 1 : -1))
		this.refreshPage()
	}

	onFilter(text: string): Array<RegistroAtividade> {
		return this.registroAtividades.filter((registro) => {
			var date = this.formatedData(registro)
			const term = text.toLowerCase()
			return (
				registro.nomeArquivo!.toLowerCase().includes(term) ||
				registro.nomeProcesso!.toLowerCase().includes(term) ||
				registro.usuario!.toString().toLowerCase().includes(term) ||
				// @ts-ignore: Object is possibly 'null'.
				date.includes(term)
			)
		})
	}

	refreshPage() {
		this.pagefiltered = this.listFilter
			? this.filtered
					.map((registro, i) => ({ index: i + 1, ...registro }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
			: this.registroAtividades
					.map((registro, i) => ({ index: i + 1, ...registro }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	cleanFiltered() {
		this.collectionSize = this.registroAtividades.length
		this.listFilter = ''
		this.refreshPage()
	}

	getUserEmail() {
		this.profile = this.msalService.instance.getAllAccounts()
		if (this.profile) {
			this.usuarioService.getEmailUser(this.profile[0].username).subscribe((res) => {
				if (res.status >= 400) {
					console.log('error')
					this.showAlertInfo(res.statusText)
				} else {
					this.usuario = res.usuario
					this.getRegisterActivityForUser()
				}
			})
		}
	}

	getRegisterActivityForUser() {
		if (this.profile) {
			let query = {
				query: `SELECT * FROM r WHERE r.nomeGrupo = '${this.usuario.grupo.nome}'`,
			}
			this.infoMessage.status = 2
			this.arquivosEnviadosService.getRegistroAtividadePorUsuario(query).subscribe(
				(res) => {
					if (res.status >= 400) {
						this.showAlertInfo(res.statusText)
					} else {
						if (res._count == 0) {
							this.infoMessage = { status: 1, message: 'Não existem arquivos enviando para esse grupo!' }
						} else {
							this.infoMessage.status = 3
							this.registroAtividades = res.Documents.filter((registro) => registro.usuario != 'JobVerificacaoArquivos' && registro.usuario != 'Integração')
							this.listFilter
							this.cleanFiltered()
						}
					}
				},
				(error) => {
					let erro: number = error || error.message
				}
			)
		}
	}


	userFormated(registro) {
		return registro.usuario.nome != null ? registro.usuario.nome : registro.usuario
	}

	formatedData(register) {
		var dataHora = register.dataHora.split(' ')
		var date = dataHora[0].split('/')
		var hour = dataHora[1].split(':')
		let dateFormated = `${this.toAddZero(date[0])}-${this.toAddZero(date[1])}-${date[2]} ${this.toAddZero(hour[0])}:${this.toAddZero(hour[1])}:${this.toAddZero(
			hour[2]
		)}`
		return dateFormated
	}

	showAlertInfo(msg: any) {
		this.toastrService.error(`${msg} - Ocorreu um erro ao fazer a chamada, por favor tente mais tarde!`, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/arquivosenviados')
		this.modalRef.close()
	}
}
