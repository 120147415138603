import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

import { TipoAtividade } from 'src/app/models/tipoAtividade.model'
import { environment } from 'src/environments/environment'
import { RegistroAtividade } from 'src/app/models/registroAtividade.model'

const HOST = environment.HOST
const PATH_PTA = environment.PATH_PTA
const PATH_COSMOS = environment.PATH_COSMOS

const headers = environment.HttpHeaders
const httpOptions = { headers }
@Injectable({
	providedIn: 'root',
})
export class CalendarioService {
	constructor(private http: HttpClient) {}

	getEnvioNotificacao(anoReferencia: string): Observable<any> {
		let tipo = TipoAtividade.envioNotificacao

		const condicaoAno = `AND CONTAINS(c.mesReferenciaNotificacao,'${anoReferencia}')`
		const query = {
			query: 'SELECT * FROM c WHERE c.tipo =' + `'${tipo}'` + `${condicaoAno}`,
		}
		return this.http.post<any>(`${HOST}/${PATH_COSMOS}/pta/colecoes/registro-atividades`, JSON.stringify(query), httpOptions).pipe(
			tap((_) => console.log(`Response getEnvioNotificacao`)),
			catchError(this.handleError<any>('getEnvioNotificacao', null))
		)
	}

	createEnvioNotificacao(registro: RegistroAtividade): Observable<any> {
		return this.http.post<RegistroAtividade>(`${HOST}/${PATH_PTA}/registroatividade`, JSON.stringify(registro), httpOptions).pipe(
			tap((_) => console.log(`Response createEnvioNotificacao`)),
			catchError(this.handleError<any>('createEnvioNotificacao', null))
		)
	}

	update(registro: RegistroAtividade) {
		return this.http.put<RegistroAtividade>(`${HOST}/${PATH_PTA}/registroatividades`, registro, httpOptions).pipe(
			tap((_) => console.log(`Response updateEnvioNotificacao`)),
			catchError(this.handleError<any>('updateEnvioNotificacao', null))
		)
	}

	delete(registro: RegistroAtividade) {
    console.log(registro, 'delete')
		return this.http.delete<RegistroAtividade>(`${HOST}/${PATH_PTA}/registroatividades/${registro.id}`, httpOptions).pipe(
			tap((_) => console.log(`Response deleteEnvioNotificacao`)),
			catchError(this.handleError<RegistroAtividade>('deleteEnvioNotificacao'))
		)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}
}
