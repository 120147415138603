import { Component } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { OrderPipe } from 'ngx-order-pipe'
import { ToastrService } from 'ngx-toastr'
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { Processo } from 'src/app/models/processo.model'
import { CheckProfile } from 'src/app/utils/checkProfile.util'
import { ProcessoService } from './processos.service'
import { LoginService } from './../../shared/login.service'

@Component({
	selector: 'app-processos',
	templateUrl: './processos.component.html',
	styleUrls: ['./processos.component.scss'],
})
export class ProcessosComponent {
	processo: Processo = new Processo()
	processos: Array<Processo> = []
	processosFilter: Array<Processo> = []

	dataSaved = false
	title = 'PTA'
	responsavel_valiaProcesso = ['Arrecadação', 'Cadastro', 'Beneficios', 'Empréstimos']

	infoMessage = { status: 0, message: '' }

	createProcessoForm: FormGroup
	updateProcessoForm: FormGroup
	deleteProcessoForm: FormGroup

	closeResult: string
	modalRef: NgbModalRef
	modalOptions: NgbModalOptions

	_listFilter: string
	filtered: Array<Processo> = []

	// paginação
	page = 1
	pageSize = 15
	collectionSize: number
	pagefiltered: Array<Processo> = []
	order: string = 'nome'
	key: string = 'nome'
	reverse: boolean = false
	onSort(key) {
		this.key = key
		this.reverse = !this.reverse
	}

	perfilNormativo = false
	perfilTecnico = false
	loginDisplay = false

	constructor(
		private processoService: ProcessoService,
		private orderPipe: OrderPipe,
		private formBuilder: FormBuilder,
		private toastrService: ToastrService,
		private modalService: NgbModal,
		private router: Router,
		private checked: CheckProfile,
		private loginService: LoginService
	) {
		this.getProcessos()
		this.createForm()
		this.updateForm()
		this.deleteForm()

		this.orderPipe.transform(this.processos, this.order)
		this.orderPipe.transform(this.pagefiltered, this.order)

		this.loginService.ngOnInit()
		this.loginDisplay = this.loginService.authenticated

		this.perfilNormativo = this.checked.checkedPerfilNormativo()
		this.perfilTecnico = this.checked.checkedPerfilTecnico()
	}

	ngOnInit() {
		//this.createMessageForm.reset();
	}

	resetPage() {
		this.getProcessos()
		this.createForm()
		this.updateForm()
		this.deleteForm()
	}

	get listFilter(): string {
		return this._listFilter
	}

	set listFilter(value: string) {
		this.filtered = this.processos
		this._listFilter = value
		this.filtered = this.listFilter ? this.onFilter(this.listFilter) : this.processos
		this.collectionSize = this.filtered.length
		this.pagefiltered = this.filtered

		this.refreshPage()
	}

	onFilter(text: string): Array<Processo> {
		return this.processos.filter((processo) => {
			const term = text.toLowerCase()
			return processo.nome.toLowerCase().includes(term) || processo.descricao.toLowerCase().includes(term)
		})
	}

	refreshPage() {
		this.pagefiltered = this.listFilter
			? this.filtered
					.map((processo, i) => ({ index: i + 1, ...processo }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
			: this.processos
					.map((processo, i) => ({ index: i + 1, ...processo }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	cleanFiltered() {
		this.collectionSize = this.processos.length
		this.listFilter = ''
		this.refreshPage()
	}

	getProcessos() {
		this.infoMessage.status = 2
		this.processoService.getProcessos().subscribe((res) => {
			if (res.status >= 400) {
				this.showAlertInfo(res.statusText)
			} else {
				if (res._count == 0) {
					this.infoMessage = { status: 1, message: 'Não existem processos cadastrados!' }
				} else {
					this.infoMessage.status = 3
					this.processos = res.processos
					this.processosFilter = this.processos
					this.collectionSize = this.processos.length
					this.refreshPage()
				}
			}
		})
	}

	onChange(event, form) {
		if (event.target.value) form.responsavel_valia = event.target.value
	}

	createForm() {
		this.createProcessoForm = this.formBuilder.group({
			nome: new FormControl('', [Validators.required]),
			descricao: new FormControl('', [Validators.required]),
			responsavel_valia: new FormControl('', [Validators.required]),
		})
	}

	onCreate(processo: Processo) {
		this.processoService.create(processo).subscribe(
			(res) => {
				this.dataSaved = true
				this.getProcessos()
				this.createProcessoForm.reset()
				if (res.error === undefined) {
					this.showSucessCreate('Registro criado com sucesso.')
				} else {
					this.showErrorUpdate(res.error)
				}
			},
			(error) => {
				let erro: number = error || error.messsage
				this.showErrorUpdate(erro)
			}
		)
	}

	update(processo: Processo, form) {
		this.openModal(form)
		this.updateProcessoForm.patchValue({
			id: processo.id,
			descricao: processo.descricao,
			nome: processo.nome,
			responsavel_valia: processo.responsavel_valia,
		})
		this.processo = processo
		this.filtered = this.processos
		this.cleanFiltered()
	}

	updateForm() {
		this.updateProcessoForm = this.formBuilder.group({
			id: new FormControl(''),
			nome: new FormControl('', [Validators.required]),
			descricao: new FormControl('', [Validators.required]),
			responsavel_valia: new FormControl('', [Validators.required]),
		})
	}

	onUpdate(form) {
		let processo = JSON.parse(JSON.stringify(form.value))
		this.processoService.update(processo).subscribe(
			(res) => {
				if (res.status === 'OK') {
					this.showSucessUpdate('Registro atualizado com sucesso.')
				} else {
					this.showErrorUpdate('Erro ao tentar alterar Processo, Mensagem: ' + res.status)
				}
			},
			(error) => {
				let erro: number = error || error.messsage
				this.showErrorUpdate(erro)
			}
		)
	}

	delete(processo: Processo, form) {
		this.openModal(form)
		this.deleteProcessoForm.patchValue({
			id: processo.id,
			nome: processo.nome,
			descricao: processo.descricao,
			responsavel_valia: processo.responsavel_valia,
		})
		this.processo = processo
		this.cleanFiltered()
	}

	deleteForm() {
		this.deleteProcessoForm = this.formBuilder.group({
			id: [{ value: '', disabled: true }],
			nome: [{ value: '', disabled: true }],
			descricao: [{ value: '', disabled: true }],
			responsavel_valia: [{ value: '', disabled: true }],
		})
	}

	onDelete(form) {
		this.processo = JSON.parse(JSON.stringify(form.value))
		this.processoService.delete(this.processo).subscribe(
			(res) => {
				this.showSucessDelete('Registro apagado com sucesso.')
			},
			(error) => {
				let erro: number = error || error.messsage
				this.showErrorDelete(erro)
			}
		)
	}

	openModal(form) {
		this.modalRef = this.modalService.open(form)
		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}

	showSucessCreate(msg: any) {
		this.toastrService.success(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/processos')
		this.getProcessos()
		this.modalRef.close()
	}

	showErrorCreate(msg: any) {
		this.toastrService.error(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/processos')
		this.getProcessos()
		this.modalRef.close()
	}

	showSucessUpdate(msg: any) {
		this.toastrService.success(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/processos')
		this.getProcessos()
		this.modalRef.close()
	}

	showErrorUpdate(msg: any) {
		this.toastrService.error(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/processos')
		this.getProcessos()
		this.modalRef.close()
	}

	showSucessDelete(msg: any) {
		this.toastrService.success(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/processos')
		this.getProcessos()
		this.modalRef.close()
	}

	showErrorDelete(msg: any) {
		this.toastrService.error(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/processos')
		this.getProcessos()
		this.modalRef.close()
	}

	showAlertInfo(msg: any) {
		this.toastrService.error(`${msg} - Ocorreu um erro ao fazer a chamada, por favor tente mais tarde!`, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/processos')
		this.modalRef.close()
	}
}
