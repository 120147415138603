import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { MsalService } from '@azure/msal-angular'

import { OrderPipe } from 'ngx-order-pipe'
import { ToastrService } from 'ngx-toastr'
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { environment } from 'src/environments/environment'
import { CheckProfile } from 'src/app/utils/checkProfile.util'

import { Grupo } from 'src/app/models/grupo.model'
import { Usuario } from 'src/app/models/usuario.model'
import { UsuarioExport } from 'src/app/models/usuarioExport.model'
import { Email } from 'src/app/models/email.model'

import { GrupoService } from '../grupos/grupos.service'
import { UsuarioService } from './usuarios.service'
import { EmailService } from 'src/app/shared/email.service'
import { ExportExcelService } from 'src/app/shared/exportExcel.service'
import { LoginService } from 'src/app/shared/login.service'

const sendEmail = environment.messageSendEmail

@Component({
	selector: 'app-usuarios',
	templateUrl: './usuarios.component.html',
	styleUrls: ['./usuarios.component.scss'],
})
export class UsuariosComponent implements OnInit {
	usuario: Usuario = new Usuario()
	usuarios: Array<Usuario> = []
	usuariosCheckMatricula: Array<Usuario> = []
	userEmail: any
	chaveAtualizada: string
	tipoPerfil = ['Patrocinadora', 'Consignatária', 'Empresa Parceira']
	grupos: Array<Grupo> = []
	arrayExport: Array<UsuarioExport>[]
	dataSaved = false
	title = 'PTA'
	habilitarMatricula: boolean

	infoMessage = { status: 0, message: '' }

	createUserForm: FormGroup
	updateUserForm: FormGroup
	deleteUserForm: FormGroup

	closeResult: string
	modalRef: NgbModalRef
	modalOptions: NgbModalOptions

	// search
	_listFilter: string
	filtered: Array<Usuario> = []

	// paginação
	page = 1
	pageSize = 15
	collectionSize: number
	pagefiltered: Array<Usuario> = []
	order: string = 'nome'
	key: string = 'nome'
	reverse: boolean = false
	onSort(key) {
		this.key = key
		this.reverse = !this.reverse
	}

	perfilNormativo = false
	perfilTecnico = false
	loginDisplay = false

	constructor(
		private usuarioService: UsuarioService,
		private grupoService: GrupoService,
		private emailService: EmailService,
		private modalService: NgbModal,
		private orderPipe: OrderPipe,
		private formBuilder: FormBuilder,
		private toastrService: ToastrService,
		private router: Router,
		private exportExcelService: ExportExcelService,
		private checked: CheckProfile,
		private msalService: MsalService,
		private loginService: LoginService
	) {
		this.getUsuarios()
		this.getGrupos()
		this.getEmailUser()
		this.getUsuariosExport()
		this.createForm()
		this.updateForm()
		this.deleteForm()

		this.orderPipe.transform(this.usuario, this.order)

		this.loginService.ngOnInit()
		this.loginDisplay = this.loginService.authenticated

		this.perfilNormativo = this.checked.checkedPerfilNormativo()
		this.perfilTecnico = this.checked.checkedPerfilTecnico()
	}

	ngOnInit(): void {
		this.createUserForm.reset()
	}

	resetPage() {
		this.getUsuariosExport()
		this.getUsuarios()
		this.getGrupos()
		this.createForm()
		this.updateForm()
		this.deleteForm()
	}

	get listFilter(): string {
		return this._listFilter
	}

	set listFilter(value: string) {
		this.filtered = this.usuarios
		this._listFilter = value
		this.filtered = this.listFilter ? this.onFilter(this.listFilter) : this.usuarios
		this.collectionSize = this.filtered.length
		this.pagefiltered = this.filtered

		this.refreshPage()
	}

	onFilter(text: string): Array<Usuario> {
		return this.usuarios.filter((usuario) => {
			const term = text.toLowerCase()
			return (
				usuario.nome.toLowerCase().includes(term) ||
				usuario.perfil.toLowerCase().includes(term) ||
				usuario.email.toLowerCase().includes(term) ||
				usuario.grupo.nome.toLowerCase().includes(term)
			)
		})
	}

	refreshPage() {
		this.pagefiltered = this.listFilter
			? this.filtered
					.map((usuario, i) => ({ index: i + 1, ...usuario }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
			: this.usuarios
					.map((usuario, i) => ({ index: i + 1, ...usuario }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	cleanFiltered() {
		this.collectionSize = this.usuarios.length
		this.listFilter = ''
		this.refreshPage()
	}

	getEmailUser() {
		let usuario: any
		const profile = this.msalService.instance.getAllAccounts()
		if (profile) {
			usuario = profile[0].username
		}
		this.usuarioService.getEmailUser(usuario).subscribe((res) => {
			if (res.status >= 400) {
				this.showAlertInfo(res.statusText)
			} else {
				this.userEmail = res.usuario
			}
		})
	}

	getUsuarios() {
		this.infoMessage.status = 2
		this.usuarioService.getUsuarios().subscribe((res) => {
			if (res.status >= 400) {
				this.showAlertInfo(res.statusText)
			} else {
				if (res._count == 0) {
					this.infoMessage = { status: 1, message: 'Não exitem usuarios cadastrados!' }
				} else {
          this.infoMessage.status = 3
					this.usuarios = res.usuarios.filter((user) => user.status === 'ativo')
					this.usuariosCheckMatricula = res.usuarios

					if (this.userEmail) {
						let usuario = this.usuarios.find((user) => user.id === this.userEmail.id)
						this.usuario = usuario!

						//Tratamento recuperação alteração da chave
						var objetoMaiorValor = this.usuariosCheckMatricula.sort((a, b) => (a.chave > b.chave ? -1 : 1))[0] //DESC
						var chave = Number(objetoMaiorValor.chave.replace(/[^0-9]/g, ''))
						var chaveTeste = (chave + 1).toString()
						this.chaveAtualizada = 'PTA' + chaveTeste.padStart(5, '0')
						this.createForm()
					}
					this.collectionSize = this.usuarios.length
					this.refreshPage()
				}
			}
		})
	}

	getUsuariosExport() {
		this.usuarioService.getUsuariosExport().subscribe((res) => {
			if (res.status >= 400) {
				this.showAlertInfo(res.statusText)
			} else {
				this.arrayExport = res.usuarios
			}
		})
	}

	getGrupos() {
		this.grupoService.getGrupos().subscribe((res) => {
			if (res.status >= 400) {
				this.showAlertInfo(res.statusText)
			}
			this.grupos = res.grupos
		})
	}

	onChangeCheckBox(event) {
		if (event.target.checked) {
			this.habilitarMatricula = true
		} else {
			this.habilitarMatricula = false
		}
	}

	onChangePerfil(event, form) {
		if (event.target.value) form.perfil = event.target.value
	}

	onChangeGrupo(event, form) {
		if (event.target.value) form.grupo = event.target.value
	}

	createForm() {
		this.createUserForm = this.formBuilder.group({
			chave: new FormControl(this.chaveAtualizada),
			nome: new FormControl('', Validators.required),
			status: new FormControl('ativo'),
			perfil: new FormControl('', Validators.required),
			email: new FormControl('', Validators.required),
			grupo: new FormControl('', Validators.required),
		})
	}

	onCreate(form) {
		let usuario = JSON.parse(JSON.stringify(form.value))
		usuario.status = 'ativo'
		this.usuarioService.create(usuario).subscribe(
			(res) => {
				this.dataSaved = true
				this.createUserForm.reset()
				if (res) {
					if (res.status === 400) {
						this.showErrorCreate(res.error)
					} else {
						const emailSendCadastro = new Email()
						emailSendCadastro.assunto = 'Acesso PTA'
						emailSendCadastro.destinatarios = [usuario.email]
						emailSendCadastro.corpo = sendEmail.replace('{0}', usuario.nome)

						if (!emailSendCadastro.destinatarios[0].match('@vale')) {
							this.emailService.sendEmail(emailSendCadastro).subscribe(
								(_res) => {
									console.log('E-mail enviado!')
								},
								(error) => {
									console.log(error)
								}
							)
						}

						this.showSucessCreate('Registro criado com sucesso.')
					}
				}

				this.ngOnInit()
			},
			(error) => {
				let erro: number = error || error.messsage
				this.showErrorCreate(erro)
			}
		)
	}

	update(usuario: Usuario, form) {
		this.openModal(form)
		this.updateUserForm.patchValue({
			id: usuario.id,
			chave: usuario.chave,
			perfil: usuario.perfil,
			nome: usuario.nome,
			status: usuario.status,
			email: usuario.email,
			grupo: usuario.grupo,
		})
		this.usuario = usuario
		this.cleanFiltered()
	}

	updateForm() {
		this.updateUserForm = this.formBuilder.group({
			id: new FormControl(''),
			chave: new FormControl(''),
			status: new FormControl(''),
			nome: new FormControl('', Validators.required),
			perfil: new FormControl('', Validators.required),
			email: new FormControl('', Validators.required),
			grupo: new FormControl('', Validators.required),
		})
	}

	onUpdate(form) {
		this.onChangePerfil(event, form)
		this.usuario = form.value
		this.usuarioService.update(this.usuario).subscribe(
			(res) => {
				if (res) {
					if (res.ok === false) {
						this.showErrorUpdate(res.error.message)
					} else {
						this.showSucessUpdate('Registro atualizado com sucesso.')
					}
				}
			},
			(error) => {
				let erro: number = error || error.messsage
				this.showErrorUpdate(erro)
			}
		)
	}

	delete(usuario: Usuario, form) {
		this.openModal(form)
		this.deleteUserForm.patchValue({
			id: usuario.id,
			chave: usuario.chave,
			perfil: usuario.perfil,
			nome: usuario.nome,
			status: usuario.status,
			email: usuario.email,
			grupo: usuario.grupo,
		})
		this.usuario = usuario
		this.cleanFiltered()
	}

	deleteForm() {
		this.deleteUserForm = this.formBuilder.group({
			id: [{ value: '', disabled: true }],
			chave: [{ value: '', disabled: true }],
			status: [{ value: '', disabled: true }],
			nome: [{ value: '', disabled: true }],
			perfil: [{ value: '', disabled: true }],
			email: [{ value: '', disabled: true }],
			grupo: [{ value: '', disabled: true }],
		})
	}

	onDelete(form) {
		this.usuario = JSON.parse(JSON.stringify(form.value))
		this.usuario.status = 'inativo'
		this.usuarioService.update(this.usuario).subscribe(
			(res) => {
				if (res) {
					if (res.ok === false) {
						this.showErrorUpdate(res.error.message)
					} else {
						this.resetPage()
						this.showSucessUpdate('Usuário excluido com sucesso.')
					}
				}
			},
			(error) => {
				let erro: number = error || error.messsage
				this.showErrorUpdate(erro)
			}
		)
	}

	disabledButtonInputValid(form: any) {
		console.log(form, 'form')
		if (
			form.value.chave == '' ||
			form.value.status == '' ||
			form.value.nome == '' ||
			form.value.perfil == '' ||
			form.value.email == '' ||
			form.value.grupo == ''
		) {
			return true
		} else {
			return false
		}
	}

	export() {
		this.exportExcelService.exportExcel(this.arrayExport, 'UsuariosPTA')
	}

	openModal(form) {
		this.modalRef = this.modalService.open(form)
		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}

	showSucessCreate(msg: any) {
		this.toastrService.success(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/usuarios')
		this.getUsuarios()
		this.modalRef.close()
	}

	showErrorCreate(msg: any) {
		this.toastrService.error(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/usuarios')
		this.getUsuarios()
		this.modalRef.close()
	}

	showSucessUpdate(msg: any) {
		this.toastrService.success(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/usuarios')
		this.getUsuarios()
		this.modalRef.close()
	}

	showErrorUpdate(msg: any) {
		this.toastrService.error(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/usuarios')
		this.getUsuarios()
		this.modalRef.close()
	}

	showAlertInfo(msg: any) {
		this.toastrService.error(`${msg} - Ocorreu um erro ao fazer a chamada, por favor tente mais tarde!`, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/usuarios')
		this.modalRef.close()
	}
}
