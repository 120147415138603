<div *ngIf="loginDisplay" class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<h4 class="title">Listar usuários</h4>
		<div class="input-group search w-25" style="margin-right: 0">
			<input class="form-control" type="text" placeholder="Pesquisar" [(ngModel)]="listFilter" />
			<div class="input-group-append">
				<button type="button" class="btn-input-customer" arial-label="Close" (click)="cleanFiltered()">
					<em i-bs name="x"></em>
				</button>
			</div>
		</div>
	</div>
	<div class="card-header py-4 d-flex justify-content-between align-items-center">
		<div *ngIf="!perfilNormativo || perfilTecnico" id="buttonCreate" class="buttons">
			<button class="btn btn-customer-info btn-customer-create" ngbTooltip="Criar usuário no sistema" (click)="openModal(modalCreate)">
				<em i-bs name="plus" width="20" height="20"></em>
			</button>
		</div>
		<button type="button" class="btn btn-customer-info btn-customer-export" ngbTooltip="Exportar" (click)="export()">
			<em i-bs name="arrow-bar-down" width="20" height="20"></em>
		</button>
	</div>
	<div class="card-body h-auto">
    <div class="d-flex justify-content-center align-items-center py-5" *ngIf="infoMessage.status == 1">
			<strong>{{ infoMessage.message }}</strong>
		</div>

		<div class="d-flex justify-content-center align-items-center py-5" *ngIf="infoMessage.status == 2">
			<div class="spinner-border text-primary" role="status"></div>
		</div>

		<div class="table-responsive" *ngIf="usuarios && infoMessage.status == 3">
			<table class="table table-sm table-hover align-middle" summary="Usuarios">
				<thead class="table-light">
					<tr>
						<th scope="col" (click)="onSort('nome')">
							Nome <span *ngIf="key == 'nome'">{{ reverse ? '▲' : '▼' }}</span>
						</th>
						<th scope="col" (click)="onSort('email')">
							Email<span *ngIf="key == 'email'">{{ reverse ? '▲' : '▼' }}</span>
						</th>
						<th scope="col" (click)="onSort('perfil')">
							Perfil<span *ngIf="key == 'perfil'">{{ reverse ? '▲' : '▼' }}</span>
						</th>
						<th scope="col" (click)="onSort('grupo')">
							Grupo<span *ngIf="key == 'grupo'">{{ reverse ? '▲' : '▼' }}</span>
						</th>
						<th scope="col" *ngIf="!perfilNormativo || perfilTecnico" class="text-center align-middle">Editar</th>
						<th scope="col" *ngIf="!perfilNormativo || perfilTecnico" class="text-center align-middle">Apagar</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let usuario of pagefiltered | orderBy : order : reverse; let i = index">
						<td>{{ usuario.nome }}</td>
						<td>{{ usuario.email }}</td>
						<td>{{ usuario.perfil }}</td>
						<td style="text-transform: uppercase">{{ usuario.grupo?.nome }}</td>
						<td *ngIf="!perfilNormativo || perfilTecnico" class="text-center align-middle">
							<button type="button" class="btn btn-customer-info" (click)="update(usuario, modalUpdate)">
								<em i-bs name="pen-fill"></em>
							</button>
						</td>
						<td *ngIf="!perfilNormativo || perfilTecnico" class="text-center align-middle">
							<button type="button" class="btn btn-customer-danger" (click)="delete(usuario, modalDelete)">
								<em i-bs name="trash-fill"></em>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card-footer-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<ngb-pagination
			class="pagination-customer"
			[collectionSize]="collectionSize"
			[(page)]="page"
			[pageSize]="pageSize"
			(pageChange)="refreshPage()"
		></ngb-pagination>

		<select class="pagination-select-customer" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshPage()">
			<option [ngValue]="15">15 items por página</option>
			<option [ngValue]="20">20 items por página</option>
			<option [ngValue]="25">25 items por página</option>
		</select>
	</div>
</div>

<ng-template #modalCreate let-modal>
	<div class="modal-header">
		<h4 class="title" id="modal-basic-title">Cadastrar usuário</h4>
		<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<em i-bs name="x" width="20" height="20"></em>
		</button>
	</div>
	<div class="modal-body">
		<form class="forms-sample" [formGroup]="createUserForm">
			<div class="form-group">
				<label for="nome" class="d-flex align-items-center"
					><input type="checkbox" (change)="onChangeCheckBox($event)" style="margin-right: 0.5rem" />Preencher matrícula manualmente</label
				>
			</div>
			<div class="form-group" *ngIf="habilitarMatricula">
				<input type="text" class="form-control border-opacity-50" formControlName="chave" id="chave" placeholder="Chave" />
			</div>
			<div class="form-group">
				<input type="text" class="form-control border-opacity-50" formControlName="nome" id="nome" placeholder="Nome" />
			</div>
			<div class="form-group">
				<input type="email" class="form-control border-opacity-50" formControlName="email" id="email" placeholder="Email" />
			</div>
			<div class="form-group">
				<label>Perfil</label>
				<select class="form-select form-control border-opacity-50" formControlName="perfil" (change)="onChangePerfil($event, createUserForm)">
					<option disabled selected>Escolha um perfil</option>
					<option *ngFor="let tipo of tipoPerfil" [ngValue]="tipo">
						{{ tipo }}
					</option>
				</select>
			</div>
			<div class="form-group">
				<label>Grupo</label>
				<select class="form-select form-control border-opacity-50" formControlName="grupo" (change)="onChangeGrupo($event, createUserForm)">
					<option disabled selected>Escolha um grupo</option>
					<option *ngFor="let grupo of grupos" [ngValue]="grupo" style="text-transform: uppercase">
						{{ grupo.nome }}
					</option>
				</select>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn-modal-customer-info" (click)="onCreate(createUserForm)" [disabled]="!createUserForm.valid">OK</button>
		<button type="button" class="btn-modal-customer-danger" (click)="modal.dismiss('Cross click')">CANCELAR</button>
	</div>
</ng-template>

<ng-template #modalUpdate let-modal>
	<div class="modal-header">
		<h4 class="title" id="modal-basic-title">Editar usuário</h4>
		<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<em i-bs name="x" width="20" height="20"></em>
		</button>
	</div>
	<div class="modal-body">
		<form class="forms-sample" [formGroup]="updateUserForm">
			<div class="form-group">
				<input type="text" class="form-control border-opacity-50" formControlName="nome" id="nome" placeholder="Nome"/>
			</div>
			<div class="form-group">
				<input type="email" class="form-control border-opacity-50" formControlName="email" id="email" placeholder="Email"/>
			</div>
			<div class="form-group">
				<select class="form-control form-select border-opacity-50" formControlName="perfil" (change)="onChangePerfil($event, updateUserForm)">
					<option *ngFor="let tipo of tipoPerfil" [ngValue]="tipo" style="text-transform: capitalize">
						{{ tipo }}
					</option>
				</select>
			</div>
			<div class="form-group">
				<select class="form-control form-select border-opacity-50" formControlName="grupo" (change)="onChangeGrupo($event, updateUserForm)">
					<option disabled [selected]="true" [ngValue]="usuario.grupo" style="text-transform: uppercase">
						{{ usuario.grupo.nome }}
					</option>
					<option *ngFor="let grupo of grupos" [ngValue]="grupo" style="text-transform: uppercase">
						{{ grupo.nome }}
					</option>
				</select>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn-modal-customer-info" (click)="onUpdate(updateUserForm)" [disabled]="!updateUserForm.valid">OK</button>
		<button type="button" class="btn-modal-customer-danger" (click)="modal.dismiss('Cross click')">CANCELAR</button>
	</div>
</ng-template>

<ng-template #modalDelete let-modal>
	<div class="modal-header">
		<h4 class="title" id="modal-basic-title">Apagar usuário</h4>
		<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<em i-bs name="x" width="20" height="20"></em>
		</button>
	</div>
	<div class="modal-body">
		<fieldset>
			<legend>Deseja realmente excluir esse registro ?</legend>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn-modal-customer-info" (click)="onDelete(deleteUserForm)">OK</button>
		<button type="button" class="btn-modal-customer-danger" (click)="modal.dismiss('Cross click')">CANCELAR</button>
	</div>
</ng-template>
