import { Component, OnInit } from '@angular/core'
import { MsalService } from '@azure/msal-angular'
import { environment } from 'src/environments/environment'

const ambiente = environment.descricaoAmbiente

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	imgPath = '../../assets/images/Logohorizontal.png'
	user: any = ''
	ambiente: string = ambiente

	constructor(private msalService: MsalService) {}

	ngOnInit(): void {
		this.getProfile()
	}

	getProfile() {
		const profile = this.msalService.instance.getAllAccounts()
		this.user = profile[0].name
	}

	onLogout() {
		this.msalService.logout()
		localStorage.removeItem('token')
	}
}
