import { Injectable } from '@angular/core'
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError, map, tap, timeout } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { Documento } from 'src/app/models/documento.model'
import { TipoAtividade } from 'src/app/models/tipoAtividade.model'

const HOST = environment.HOST
const PATH_PTA = environment.PATH_PTA
const PATH_COSMOS = environment.PATH_COSMOS
const PATH_BLOB = environment.PATH_BLOB

const headers = environment.HttpHeaders
const httpOptions = { headers }

@Injectable()
export class UploadInternoService {
	constructor(private http: HttpClient) {}

	getFiles(nomeArquivo: string): Observable<any> {
		return this.http.get<any>(`${HOST}/${PATH_BLOB}/arquivos/${nomeArquivo}?diretorio=pta-out`, httpOptions).pipe(
			timeout(5000),
			tap((_) => console.log(`Response getFiles`)),
			catchError(this.handleError<any>('getFiles', null))
		)
	}

	getRegistros(processo: string): Observable<any> {
		let tipo = TipoAtividade.upload
		const query = {
			query: `SELECT * FROM c WHERE c.tipo = '${tipo}' AND c.nomeProcesso = '${processo}'`,
		}
		return this.http.post<any>(`${HOST}/${PATH_COSMOS}/pta/colecoes/registro-atividades`, JSON.stringify(query), httpOptions).pipe(
			tap((_) => console.log(`Response getRegistros`)),
			catchError(this.handleError<any>('getRegistros', null))
		)
	}

  getUsuariosByGrupo(id: string): Observable<any> {
		const query = {
			query: `SELECT * FROM c WHERE c.grupo.id = '${id}'`,
		}
		return this.http.post<any>(`${HOST}/${PATH_COSMOS}/pta/colecoes/usuarios`, JSON.stringify(query), httpOptions).pipe(
			tap((_) => console.log(`Response getRegistros`)),
			catchError(this.handleError<any>('getRegistros', null))
		)
	}

	enviarDocumento(formData: any, documento: Documento) {
		return this.http
			.post<any>(
				`${HOST}/${PATH_PTA}/arquivos/internos`,
				JSON.stringify(documento), //httpOptions
				{
					headers,
					reportProgress: true,
					observe: 'events',
				}
			)
			.pipe(
				timeout(300000),
				tap((_) => console.log(`Response enviarDocumento`)),
				map((event) => this.getEventMessage(event, formData, documento)),
				catchError(this.handleError('Envio de Documento', HttpEventType.Response))
			)
	}

	private apiResponse(event) {
		return event
	}

	private fileUploadProgress(event) {
		const percentDone = Math.round((100 * event.loaded) / event.total)
		return { status: 'progress', message: percentDone }
	}

	private getEventMessage(event: HttpEvent<any>, formData, documento: Documento) {
		switch (event.type) {
			case HttpEventType.UploadProgress:
				return this.fileUploadProgress(event)
			case HttpEventType.Response:
				return this.apiResponse(event)
			default:
				return `File "${formData.get('profile').name}" surprising upload event: ${event.type}.`
		}
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}
}
