<div *ngIf="loginDisplay && mensagem" class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-4 px-0">
		<h4 class="title">Editor de mensagem</h4>
	</div>
	<div class="card-body py-5">
		<form class="forms-sample" [formGroup]="updateMessageForm">
			<div class="form-group border-info div-ckeditor">
				<ckeditor [editor]="Editor" [config]="config" data="{{ mensagem.texto }}" id="texto" formControlName="texto"></ckeditor>
			</div>
			<div>
				<button type="button" class="btn btn-info btn-customer-info btn-customer-edit float-right" (click)="update(mensagem, updateMessageForm)">
					<em i-bs name="pen-fill"></em>
				</button>
			</div>
		</form>
	</div>
</div>
