import { Empresa } from './empresa.model';
import { NotificacaoEnvio } from './notificacaoEnvio';
import { Processo } from './processo.model';

//import { PeriodoEnvio } from "./PeriodoEnvio.model";

export class Grupo {
  id: string;
  nome: string;
  empresas: Array<Empresa> = [];
  // responsavel: Responsavel;
  // periodoEnvio: PeriodoEnvio;
  enviarProcessos: Array<Processo> = [];
  notificacaoEnvio: NotificacaoEnvio;
}
