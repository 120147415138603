import { Component, Inject, Injectable, OnDestroy, OnInit } from '@angular/core'
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular'
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser'
import { filter, Subject, takeUntil } from 'rxjs'
import { AuthGuardService } from './guards/auth.guard.service'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'pta'
	loginDisplay = false
	isIframe = false
	isLoading: Subject<boolean>
	private readonly _destroying$ = new Subject<void>()
	private isAdminGroup = false
	groups: any = []
	perfis = environment.perfisUser

	perfilAdmin = false
	perfilPatrocInterna = false
	perfilNormativo = false
	perfilTecnico = false
	perfilCadastro = false
	perfilArrecadacao = false
	perfilEmprestimo = false
	perfilConsignataria = false

	constructor(
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private broadcastService: MsalBroadcastService,
		private msalService: MsalService
	) {}

	ngOnInit() {
		this.isIframe = window !== window.parent && !window.opener

		this.broadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntil(this._destroying$)
			)
			.subscribe(() => {
				this.setLoginDisplay()
			})
	}

	async logIn() {
		if (this.msalGuardConfig.authRequest) {
			this.msalService.loginRedirect({
				...this.msalGuardConfig.authRequest,
			} as RedirectRequest)
		} else {
			this.msalService.loginRedirect()
		}
	}

	setLoginDisplay() {
		this.loginDisplay = this.msalService.instance.getAllAccounts() ? true : false
	}

	ngOnDestroy(): void {
		this._destroying$.next(undefined)
		this._destroying$.complete()
	}
}
