import { Grupo } from "./grupo.model";

export class Usuario {
  id: string;
  chave: string;
  perfil: string;
  nome: string;
  status: string;
  email: string;
  grupo: Grupo;
}