import { Injectable, LOCALE_ID, NgModule } from '@angular/core'
import { DatePipe, registerLocaleData } from '@angular/common'
import localePt from '@angular/common/locales/pt'
import { HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgbDatepickerModule, NgbInputDatepickerConfig, NgbModule, NgbProgressbarModule, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap'
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons'
import { OrderModule } from 'ngx-order-pipe'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { ToastrModule } from 'ngx-toastr'

import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular'
import { InteractionType, PublicClientApplication } from '@azure/msal-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { NavbarComponent } from './components/navbar/navbar.component'
import { SidebarComponent } from './components/sidebar/sidebar.component'
import { FooterComponent } from './components/footer/footer.component'
import { ContentComponent } from './components/content/content.component'

import { HomeComponent } from './pages/home/home.component'
import { ProcessosComponent } from './pages/processos/processos.component'
import { EmpresasComponent } from './pages/empresas/empresas.component'
import { UsuariosComponent } from './pages/usuarios/usuarios.component'
import { MensagensComponent } from './pages/mensagens/mensagens.component'
import { GruposComponent } from './pages/grupos/grupos.component'
import { CalendarioComponent } from './pages/calendario/calendario.component'
import { UploadComponent } from './pages/upload/upload.component'
import { ArquivosenviadosComponent } from './pages/arquivosenviados/arquivosenviados.component'
import { UploadInternoComponent } from './pages/upload-interno/upload-interno.component'

import { UploadService } from './pages/upload/upload.service'
import { UploadInternoService } from './pages/upload-interno/upload-interno.service'

import { LoaderComponent } from './shared/loader/loader.component'
import { LoaderInterceptor } from './shared/loader/loader.interceptor'
import { LoginService } from './shared/login.service'
import { LoaderService } from './shared/loader/loader.service'

import { CheckProfile } from './utils/checkProfile.util'
import { msalConfig, protectedResources } from './app.config'

import { AdicionarVirgulaPipe } from './utils/adicionar-virgula.pipe';




registerLocaleData(localePt)

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		const authReq = req.clone({
			setHeaders: {
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache',
			},
		})
		return next.handle(authReq)
	}
}

@NgModule({ declarations: [
        AppComponent,
        NavbarComponent,
        SidebarComponent,
        ContentComponent,
        FooterComponent,
        HomeComponent,
        ProcessosComponent,
        EmpresasComponent,
        GruposComponent,
        UsuariosComponent,
        MensagensComponent,
        CalendarioComponent,
        LoaderComponent,
        ArquivosenviadosComponent,
        UploadComponent,
        AdicionarVirgulaPipe,
        UploadInternoComponent,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        OrderModule,
        NgbModule,
        NgbProgressbarModule,
        NgxBootstrapIconsModule.pick(allIcons),
        CKEditorModule,
        ToastrModule.forRoot(),
        NgbDatepickerModule,
        MsalModule.forRoot(new PublicClientApplication(msalConfig), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ['user.read'],
            },
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([[protectedResources.endpoint, protectedResources.scopes]]),
        })], providers: [
        NgbInputDatepickerConfig,
        NgbProgressbarConfig,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        LoaderService,
        MsalGuard,
        MsalService,
        CheckProfile,
        LoginService,
        UploadService,
        UploadInternoService,
        DatePipe,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
