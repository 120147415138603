import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, Observable, of, tap } from 'rxjs'
import { Usuario } from 'src/app/models/usuario.model'
import { environment } from 'src/environments/environment'

const HOST = environment.HOST
const PATH_PTA = environment.PATH_PTA

const headers = environment.HttpHeaders
const httpOptions = { headers }


@Injectable({
	providedIn: 'root',
})
export class UsuarioService {
	constructor(private http: HttpClient) {}

	fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
	fileExtension = '.xlsx'

	getEmailUser(emailUser: string): Observable<any> {
		if (!emailUser.trim()) {
			return of(`${emailUser} não localizado`)
		}
		return this.http.get<any>(`${HOST}/${PATH_PTA}/usuario?email=${emailUser}`, httpOptions).pipe(
			tap((_) => console.log(`Response getEmailUser`)),
			catchError(this.handleError<any>('getEmailUser', null))
		)
	}

	getUsuarios(): Observable<any> {
		return this.http.get<any>(`${HOST}/${PATH_PTA}/usuarios`, httpOptions).pipe(
			tap((_) => console.log(`Response getUsuarios`)),
			catchError(this.handleError<any>('getUsuarios', null))
		)
	}

	getUsuariosExport(): Observable<any> {
		return this.http.get<any>(`${HOST}/${PATH_PTA}/usuariosExport`, httpOptions).pipe(
			tap((_) => console.log(`Response getUsuariosExport`)),
			catchError(this.handleError<any>('getUsuariosExport', null))
		)
	}

	create(usuario: Usuario): Observable<any> {
		return this.http.post<Usuario>(`${HOST}/${PATH_PTA}/usuarios`, usuario, httpOptions).pipe(
			tap((_) => console.log(`Response createProcesso`)),
			catchError(this.handleError<Usuario>('createProcesso'))
		)
	}

	update(usuario: Usuario) {
		return this.http.put<void>(`${HOST}/${PATH_PTA}/usuarios`, JSON.stringify(usuario), httpOptions).pipe(
			tap((_) => console.log(`Response update`)),
			catchError(this.handleError<any>('update', null))
		)
	}

	delete(usuario: Usuario) {
		return this.http.delete<Usuario>(`${HOST}/${PATH_PTA}/usuarios${usuario.id}`, httpOptions).pipe(
			tap((_) => console.log(`Response deleteProcesso`)),
			catchError(this.handleError<Usuario>('deleteProcesso'))
		)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}
}
