<div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
  <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
    <em i-bs name="list" width="30" height="30"></em>
  </a>
  <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
    <li class="nav-item">
      <a [routerLink]="['/']" class="nav-link align-middle px-0" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="house-fill" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Início</span>
      </a>
    </li>
    <li *ngIf="perfilNormativo || perfilTecnico" class="nav-item">
      <a [routerLink]="['/processos']" class="nav-link align-middle px-0" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="nut-fill" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Processos</span>
      </a>
    </li>
    <li *ngIf="perfilAdmin || perfilNormativo || perfilTecnico" class="nav-item">
      <a [routerLink]="['/empresas']" class="nav-link align-middle px-0" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="bank2" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Empresas</span>
      </a>
    </li>
    <li *ngIf="perfilAdmin || perfilNormativo || perfilTecnico" class="nav-item">
      <a [routerLink]="['/grupos']" class="nav-link align-middle px-0" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="people-fill" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Grupos</span>
      </a>
    </li>
    <li *ngIf="perfilAdmin || perfilNormativo || perfilTecnico" class="nav-item">
      <a [routerLink]="['/usuarios']" class="nav-link align-middle px-0" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="person-fill" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Usuários</span>
      </a>
    </li>
    <li *ngIf="perfilNormativo || perfilTecnico" class="nav-item">
      <a [routerLink]="['/mensagens']" class="nav-link align-middle px-0" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="envelope-fill" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Mensagens</span>
      </a>
    </li>
    <li *ngIf="perfilNormativo || perfilTecnico" class="nav-item">
      <a [routerLink]="['/calendario']" class="nav-link align-middle px-0" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="calendar-day-fill" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Envio Notificação</span>
      </a>
    </li>
    <li *ngIf=" perfilCalculoRetroativo || perfilTecnico" class="nav-item">
      <a [routerLink]="['/upload/interno']" class="nav-link align-middle px-0" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="file-earmark-arrow-up" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Envio Arquivos Interno</span>
      </a>
    </li>
    <li *ngIf="perfilPatrocInterna || perfilTecnico" class="nav-item">
      <a [routerLink]="['/upload']" class="nav-link align-middle px-0" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="file-earmark-arrow-up-fill" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Enviar/Receber Arquivos</span>
      </a>
    </li>
    <li *ngIf="perfilPatrocInterna || perfilTecnico" class="nav-item">
      <a [routerLink]="['/arquivosenviados']" class="nav-link align-middle px-0" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="file-earmark-text-fill" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Arquivos Enviados</span>
      </a>
    </li>
  </ul>
  <hr>
</div>