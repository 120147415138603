import { LogLevel } from '@azure/msal-browser'
import { environment } from 'src/environments/environment'

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

export const policies = {
	authorities: {
		signUpSignIn: {
			authority: environment.authorities.signUpSignIn.authority,
		},
	},
	authorityDomain: environment.authorityDomain,
}

export const msalConfig = {
	auth: {
		clientId: environment.msalConfig.clientId,
		authority: policies.authorities.signUpSignIn.authority,
		redirectUri: environment.msalConfig.redirectUri,
		postLogoutRedirectUri: window.location.origin,
		navigateToLoginRequestUrl: false,
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: isIE,
	},
	system: {
		loggerOptions: {
			loggerCallback(loglevel, message, containsPii) {
				console.log(message)
			},
			logLevel: LogLevel.Verbose,
		},
	},
}
export const protectedResources = {
	endpoint: 'https://graph.microsoft.com/v1.0/me',
	scopes: ['user.read'],
}
