import { Component } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { OrderPipe } from 'ngx-order-pipe'
import { ToastrService } from 'ngx-toastr'
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { CheckProfile } from 'src/app/utils/checkProfile.util'
import { Empresa } from 'src/app/models/empresa.model'
import { EmpresaService } from './empresas.service'
import { LoginService } from 'src/app/shared/login.service'

@Component({
	selector: 'app-empresas',
	templateUrl: './empresas.component.html',
	styleUrls: ['./empresas.component.scss'],
})
export class EmpresasComponent {
	empresa: Empresa = new Empresa()
	empresas: Array<Empresa> = []
	empresasInativas: Array<Empresa> = []
	tipoEmpresa = ['Patrocinadora', 'Consignataria', 'Empresa Parceira']
	title = 'PTA'
	dataSaved = false

	infoMessage = { status: 0, message: '' }

	createEmpresaForm: FormGroup
	updateEmpresaForm: FormGroup
	deleteEmpresaForm: FormGroup
	modalRef: NgbModalRef
	closeResult: string

	// search
	_listFilter: string
	filtered: Array<Empresa> = []

	// paginação
	page = 1
	pageSize = 15
	collectionSize: number
	pagefiltered: Array<Empresa> = []
	order: string = 'codigo'
	key: string = 'codigo'
	reverse: boolean = false
	onSort(key) {
		this.key = key
		this.reverse = !this.reverse
	}

	perfilNormativo = false
	perfilTecnico = false
	loginDisplay = false

	constructor(
		private empresaService: EmpresaService,
		private orderPipe: OrderPipe,
		private modalService: NgbModal,
		private formBuilder: FormBuilder,
		private toastrService: ToastrService,
		private router: Router,
		private checked: CheckProfile,
		private loginService: LoginService
	) {
		this.getEmpresas()
		this.createForm()
		this.updateForm()
		this.deleteForm()

		this.orderPipe.transform(this.empresas, this.order)
		this.orderPipe.transform(this.pagefiltered, this.order)

		this.loginService.ngOnInit()
		this.loginDisplay = this.loginService.authenticated

		this.perfilNormativo = this.checked.checkedPerfilNormativo()
		this.perfilTecnico = this.checked.checkedPerfilTecnico()
	}

	get listFilter(): string {
		return this._listFilter
	}

	set listFilter(value: string) {
		this.filtered = this.empresas
		this._listFilter = value
		this.filtered = this.listFilter ? this.onFilter(this.listFilter) : this.empresas
		this.collectionSize = this.filtered.length
		this.pagefiltered = this.filtered

		this.refreshPage()
	}

	onFilter(text: string): Array<Empresa> {
		return this.empresas.filter((empresa) => {
			const term = text.toLowerCase()
			return empresa.nome_completo.toLowerCase().includes(term) || empresa.tipo.toLowerCase().includes(term) || empresa.codigo.toLowerCase().includes(term)
		})
	}

	refreshPage() {
		this.pagefiltered = this.listFilter
			? this.filtered
					.map((empresa, i) => ({ index: i + 1, ...empresa }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
			: this.empresas
					.map((empresa, i) => ({ index: i + 1, ...empresa }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	cleanFiltered() {
		this.collectionSize = this.empresas.length
		this.listFilter = ''
		this.refreshPage()
	}

	getEmpresas() {
		this.infoMessage.status = 2
		this.empresaService.getEmpresas().subscribe((res) => {
			if (res.status >= 400) {
				this.showAlertInfo(res.statusText)
			} else {
				if (res._count == 0) {
					this.infoMessage = { status: 1, message: 'Não existem empresas cadastradas!' }
				} else {
					this.infoMessage.status = 3
					this.empresas = res.empresas.filter((emp) => emp.status === 'ativo')
					this.empresasInativas = res.empresas.filter((emp) => emp.status === 'inativo')
					this.collectionSize = this.empresas.length
					this.refreshPage()
				}
			}
		})
	}

	onChange(event, form) {
		if (event.target.value) form.tipo = event.target.value
	}

	create(form) {
		this.openModal(form)
		this.createEmpresaForm.patchValue({
			id: '',
			codigo: '',
			nome_completo: '',
			tipo: '',
			status: 'ativo',
		})
	}

	createForm() {
		this.createEmpresaForm = this.formBuilder.group({
			codigo: new FormControl('', Validators.required),
			nome_completo: new FormControl('', Validators.required),
			tipo: new FormControl('', Validators.required),
			status: new FormControl('ativo'),
		})
	}

	onCreate(form) {
		let empresa = JSON.parse(JSON.stringify(form.value))
		if (!this.empresasInativas.find((emp) => emp.codigo == empresa.codigo)) {
			empresa.status = 'ativo'
			this.empresaService.create(empresa).subscribe(
				(res) => {
					this.dataSaved = true
					this.getEmpresas()
					this.createEmpresaForm.reset()
					if (res.status >= 400) {
						this.showErrorUpdate('Erro ao cadastrar empresa.')
					} else {
						this.showSucessCreate('Registro criado com sucesso.')
					}
				},
				(error) => {
					let erro: any = error || error.message
					this.showErrorCreate(erro)
				}
			)
		} else {
			this.showErrorUpdate('Código já utilizado, empresa inativa.')
		}
	}

	update(empresa: Empresa, form) {
		this.openModal(form)
		this.updateEmpresaForm.patchValue({
			id: empresa.id,
			codigo: empresa.codigo,
			nome_completo: empresa.nome_completo,
			tipo: empresa.tipo,
			status: empresa.status,
		})
		this.empresa = empresa
		this.cleanFiltered()
	}

	updateForm() {
		this.updateEmpresaForm = this.formBuilder.group({
			id: new FormControl(''),
			codigo: new FormControl({ disabled: true }),
			nome_completo: new FormControl('', Validators.required),
			tipo: new FormControl('', Validators.required),
			status: new FormControl('', Validators.required),
		})
	}

	onUpdate(form) {
		this.empresa = form.value
		this.empresaService.update(this.empresa).subscribe(
			(res) => {
				if (res) {
					this.showSucessUpdate('Registro atualizado com sucesso.')
				}
			},
			(error) => {
				let erro: number = error || error.messsage
				this.showErrorUpdate(erro)
			}
		)
	}

	delete(empresa, form) {
		this.openModal(form)
		this.deleteEmpresaForm.patchValue({
			id: empresa.id,
			codigo: empresa.codigo,
			nome_completo: empresa.nome_completo,
			tipo: empresa.tipo,
			status: empresa.status,
		})
		this.empresa = empresa
		this.cleanFiltered()
	}

	deleteForm() {
		this.deleteEmpresaForm = this.formBuilder.group({
			id: [{ value: '', disabled: true }],
			codigo: [{ value: '', disabled: true }],
			nome_completo: [{ value: '', disabled: true }],
			tipo: [{ value: '', disabled: true }],
		})
	}

	onDelete(form) {
		this.empresa = JSON.parse(JSON.stringify(form.value))
		this.empresa.status = 'inativo'
		this.empresaService.update(this.empresa).subscribe(
			(res) => {
				if (res) {
					this.showSucessUpdate('Registro apagado com sucesso.')
				}
			},
			(error) => {
				let erro: any = error || error.message
				this.showErrorUpdate(erro)
			}
		)
	}

	openModal(form) {
		this.modalRef = this.modalService.open(form)
		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}

	showSucessCreate(msg: any) {
		this.toastrService.success(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/empresas')
		this.getEmpresas()
		this.modalRef.close()
	}

	showErrorCreate(msg: any) {
		this.toastrService.error(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/empresas')
		this.getEmpresas()
		this.modalRef.close()
	}

	showSucessUpdate(msg: any) {
		this.toastrService.success(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/empresas')
		this.getEmpresas()
		this.modalRef.close()
	}

	showErrorUpdate(msg: any) {
		this.toastrService.error(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/empresas')
		this.getEmpresas()
		this.modalRef.close()
	}

	showAlertInfo(msg: any) {
		this.toastrService.error(`${msg} - Ocorreu um erro ao fazer a chamada, por favor tente mais tarde!`, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/empresas')
		this.modalRef.close()
	}
}
