import { Component } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { OrderPipe } from 'ngx-order-pipe'
import { ToastrService } from 'ngx-toastr'
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { CheckProfile } from 'src/app/utils/checkProfile.util'

import { Empresa } from 'src/app/models/empresa.model'
import { Grupo } from 'src/app/models/grupo.model'
import { Processo } from 'src/app/models/processo.model'
import { Usuario } from 'src/app/models/usuario.model'

import { EmpresaService } from '../empresas/empresas.service'
import { ProcessoService } from '../processos/processos.service'
import { UsuarioService } from '../usuarios/usuarios.service'
import { GrupoService } from './grupos.service'
import { LoginService } from 'src/app/shared/login.service'

@Component({
	selector: 'app-grupos',
	templateUrl: './grupos.component.html',
	styleUrls: ['./grupos.component.scss'],
})
export class GruposComponent {
	grupo: Grupo = new Grupo()
	grupos: Array<Grupo> = []
	empresas: Array<Empresa> = []
	usuarios: Array<Usuario> = []
	processos: Array<Processo> = []

	infoMessage = { status: 0, message: 'Não existem grupos cadastrados!' }

	empresasCompare: Array<Empresa>
	processosCompare: Array<Processo>
	empresasUpdate: Array<Empresa> = []
	processosUpdate: Array<Processo> = []

	title = 'PTA'
	dataSaved = false

	createGrupoForm: FormGroup
	updateGrupoForm: FormGroup
	deleteGrupoForm: FormGroup

	modalRef: NgbModalRef
	closeResult: string
	// search
	_listFilter: string
	filtered: Array<Grupo> = []

	// paginação
	page = 1
	pageSize = 15
	collectionSize: number
	pagefiltered: Array<Grupo> = []
	order: string = 'nome'
	key: string = 'nome'
	reverse: boolean = false
	onSort(key) {
		this.key = key
		this.reverse = !this.reverse
	}

	perfilNormativo = false
	perfilTecnico = false
	loginDisplay = false

	constructor(
		private grupoService: GrupoService,
		private empresaService: EmpresaService,
		private usuarioService: UsuarioService,
		private processoService: ProcessoService,
		private orderPipe: OrderPipe,
		private modalService: NgbModal,
		private formBuilder: FormBuilder,
		private toastrService: ToastrService,
		private router: Router,
		private checked: CheckProfile,
		private loginService: LoginService
	) {
		this.createForm()
		this.updateForm()
		this.deleteForm()

		this.orderPipe.transform(this.grupos, this.order)
		this.orderPipe.transform(this.pagefiltered, this.order)

		this.loginService.ngOnInit()
		this.loginDisplay = this.loginService.authenticated

		this.perfilNormativo = this.checked.checkedPerfilNormativo()
		this.perfilTecnico = this.checked.checkedPerfilTecnico()
	}

	ngOnInit() {
		this.getEmpresas()
		this.getProcessos()
		this.getGrupos()
		this.getUsuarios()
		this.createGrupoForm.reset()
	}

	get listFilter(): string {
		return this._listFilter
	}

	set listFilter(value: string) {
		this.filtered = this.grupos
		this._listFilter = value
		this.filtered = this.listFilter ? this.onFilter(this.listFilter) : this.grupos
		this.collectionSize = this.filtered.length
		this.pagefiltered = this.filtered

		this.refreshPage()
	}

	onFilter(text: string): Array<Grupo> {
		return this.grupos.filter((grupo) => {
			const term = text.toLowerCase()
			return grupo.nome.toLowerCase().includes(term)
		})
	}

	refreshPage() {
		this.pagefiltered = this.listFilter
			? this.filtered.map((grupo, i) => ({ index: i + 1, ...grupo })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
			: this.grupos.map((grupo, i) => ({ index: i + 1, ...grupo })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	cleanFiltered() {
		this.collectionSize = this.grupos.length
		this.listFilter = ''
		this.refreshPage()
	}

	getGrupos() {
		this.infoMessage.status = 2
		this.grupoService.getGrupos().subscribe((res) => {
			if (res.status >= 400) {
				this.showAlertInfo(res.statusText)
			} else {
				if (res._count == 0) {
					this.infoMessage = { status: 1, message: 'Não existem grupos cadastrados!' }
				} else {
					this.infoMessage.status = 3
					this.grupos = res.grupos
					this.collectionSize = this.grupos.length
					this.compareEmpresas()
					this.compareProcessos()
					this.refreshPage()
				}
			}
		})
	}

	getEmpresas() {
		this.empresaService.getEmpresas().subscribe((res) => {
			if (res.status >= 400) {
				this.showAlertInfo(res.statusText)
			} else {
				this.empresas = res.empresas.filter((emp) => emp.status === 'ativo')
			}
		})
	}

	getUsuarios() {
		this.usuarioService.getUsuarios().subscribe((res) => {
			if (res.status >= 400) {
				this.showAlertInfo(res.statusText)
			} else {
				this.usuarios = res.usuarios.filter((user) => user.status === 'ativo')
			}
		})
	}

	getProcessos() {
		this.processoService.getProcessos().subscribe((res) => {
			if (res.status >= 400) {
				this.showAlertInfo(res.statusText)
			} else {
				this.processos = res.processos
			}
		})
	}

	compareEmpresas() {
		/*
    Método que faz a comparação das empresas cadastradas para um determinado grupo e compara com o id cadastrado na collection "empresas"
     *** para recuperar os dados corretos e atualizado.
  */
		if (this.grupos.length > 0 && this.empresas.length > 0) {
			for (let indexGrupos = 0; indexGrupos < this.grupos.length; indexGrupos++) {
				if (this.grupos[indexGrupos].empresas.length > 0) {
					const empresa = this.grupos[indexGrupos].empresas
					this.empresasCompare = []
					for (let indexEmpresas = 0; indexEmpresas < empresa.length; indexEmpresas++) {
						const index = this.empresas.findIndex((val) => val.codigo === empresa[indexEmpresas].codigo)
						if (index > -1) {
							let findEmpresas = this.empresas.find((emp) => emp.codigo === empresa[indexEmpresas].codigo)
							this.empresasCompare.push(findEmpresas!)
						}
					}
					this.grupos[indexGrupos].empresas = this.empresasCompare
				}
			}
		}
	}

	compareProcessos() {
		/*
    Método que faz a comparação dos processos cadastrados para um determinado grupo e compara com o id cadastrado na collection "processos"
     *** para recuperar os dados corretos e atualizado.
  */
		if (this.grupos.length > 0 && this.processos.length > 0) {
			for (let indexGrupos = 0; indexGrupos < this.grupos.length; indexGrupos++) {
				if (this.grupos[indexGrupos].enviarProcessos.length > 0) {
					const processo = this.grupos[indexGrupos].enviarProcessos
					this.processosCompare = []
					for (let indexProcessos = 0; indexProcessos < processo.length; indexProcessos++) {
						const index = this.processos.findIndex((val) => val.id === processo[indexProcessos].id)
						if (index > -1) {
							let findProcessos = this.processos.find((proc) => proc.id === processo[indexProcessos].id)
							this.processosCompare.push(findProcessos!)
						}
					}
					this.grupos[indexGrupos].enviarProcessos = this.processosCompare
				}
			}
		}
	}

	isCheckedEmpresa(empresa) {
		var selectedUpdateEmpresas = this.empresasUpdate
		return selectedUpdateEmpresas.map((emp) => emp.id).indexOf(empresa.id) > -1
	}

	isCheckedProcesso(processo) {
		var selectedUpdateProcessos = this.processosUpdate
		return selectedUpdateProcessos.map((proc) => proc.id).indexOf(processo.id) > -1
	}

	onChangeEmpresas(empresa: any, event) {
		const selectedEmpresasGrupo = <FormArray>this.createGrupoForm.controls['empresas']
		if (event.target.checked == true) {
			selectedEmpresasGrupo.push(new FormControl(empresa))
		} else {
			let index = selectedEmpresasGrupo.controls.findIndex((emp) => emp.value == empresa)
			selectedEmpresasGrupo.removeAt(index)
		}
	}

	onChangeProcessos(processos, event) {
		const selectedProcessosGrupo = <FormArray>this.createGrupoForm.controls['enviarProcessos']
		if (event.target.checked == true) {
			selectedProcessosGrupo.push(new FormControl(processos))
		} else {
			let index = selectedProcessosGrupo.controls.findIndex((proc) => proc.value == processos)
			selectedProcessosGrupo.removeAt(index)
		}
	}

	onChangeEmpresasUpdate(empresa: any) {
		var selectedUpdateEmpresas = <FormArray>this.updateGrupoForm.controls.empresas

		var index = selectedUpdateEmpresas.value.findIndex((emp) => emp.id == empresa.id)

		if (index > -1) {
			selectedUpdateEmpresas.value.splice(index, 1)
		} else {
			selectedUpdateEmpresas.value.push(empresa)
		}
	}

	onChangeProcessosUpdate(processo: any) {
		var selectedUpdateProcessos = <FormArray>this.updateGrupoForm.controls.enviarProcessos
		var index = selectedUpdateProcessos.value.findIndex((proc) => proc == processo)

		if (index > -1) {
			selectedUpdateProcessos.value.splice(index, 1)
		} else {
			selectedUpdateProcessos.value.push(processo)
		}
	}

	createForm() {
		this.createGrupoForm = this.formBuilder.group({
			nome: new FormControl('', Validators.required),
			//empresas: this.formBuilder.array([], [Validators.required]),
			empresas: new FormArray([], Validators.required),
			periodoEnvio: this.formBuilder.group({
				dataInicio: '',
				dataFim: '',
			}),
			//enviarProcessos: this.formBuilder.array([], [Validators.required]),
			enviarProcessos: new FormArray([], Validators.required),
			notificacaoEnvio: this.formBuilder.group({
				diasAntes5: '',
				diasAntes3: '',
				diasAntes1: '',
			}),
		})
	}

	onCreate(form: Grupo) {
		this.grupoService.create(form).subscribe(
			(res) => {
				this.dataSaved = true
				this.ngOnInit()
				if (res.error === undefined) {
					this.showSucessCreate('Registro criado com sucesso.')
				} else {
					this.showErrorUpdate(res.error)
				}
			},
			(error) => {
				const erro: number = error | error.messsage
				this.showErrorCreate(erro)
			}
		)
	}

	update(grupo: Grupo, form) {
		const emp = grupo
		this.openModal(form)
		this.updateGrupoForm.patchValue({
			id: grupo.id,
			nome: grupo.nome,
			enviarProcessos: grupo.enviarProcessos,
			empresas: grupo.empresas,
			notificacaoEnvio: grupo.notificacaoEnvio,
		})
		this.empresasUpdate = grupo.empresas
		this.processosUpdate = grupo.enviarProcessos
	}

	updateForm() {
		this.updateGrupoForm = this.formBuilder.group({
			id: new FormControl('', Validators.required),
			nome: new FormControl('', Validators.required),
			empresas: new FormControl({}, Validators.required),
			periodoEnvio: this.formBuilder.group({
				dataInicio: '',
				dataFim: '',
			}),
			enviarProcessos: new FormControl({}, Validators.required),
			notificacaoEnvio: this.formBuilder.group({
				diasAntes5: '',
				diasAntes3: '',
				diasAntes1: '',
			}),
		})
	}

	onUpdate(form) {
		let grupo = JSON.parse(JSON.stringify(form.value))
		this.grupoService.update(grupo).subscribe(
			(res) => {
				this.showSucessUpdate('Registro atualizado com sucesso.')
			},
			(error) => {
				const erro: number = error | error.messsage
				this.showErrorUpdate(erro)
			}
		)
	}

	disabledButtonInputValid(form: any) {
		if (Object.keys(form.value.nome).length == 0 || Object.keys(form.value.empresas).length == 0 || Object.keys(form.value.enviarProcessos).length == 0) {
			return true
		} else {
			return false
		}
	}

	delete(grupo: { [key: string]: any }, form: any) {
		const grupos = grupo
		const usuarios = this.usuarios.filter((user) => user.grupo.nome === grupos['nome'])
		if (usuarios.length != 0) {
			this.showErrorDelete('O grupo não pode ser deletado, existem usuários cadastrados!')
		} else {
			this.openModal(form)
			this.deleteGrupoForm.patchValue(grupo)
			this.filtered = this.grupos
			this.cleanFiltered()
		}
	}

	deleteForm() {
		this.deleteGrupoForm = this.formBuilder.group({
			id: [{ value: '', disabled: true }],
			nome: [{ value: '', disabled: true }],
			empresas: [{ value: '', disabled: true }],
			periodoEnvio: [{ value: '', disabled: true }],
			enviarProcessos: [{ value: '', disabled: true }],
			notificacaoEnvio: [{ value: '', disabled: true }],
		})
	}

	onDelete(form: { value: any }) {
		const grupo = JSON.parse(JSON.stringify(form.value))
		this.grupoService.delete(grupo).subscribe(
			(res) => {
				this.showSucessDelete('Registro apagado com sucesso.')
			},
			(error) => {
				const erro: number = error | error.messsage
				this.showErrorDelete(erro)
			}
		)
	}

	onDismiss() {
		this.modalRef.dismiss()
		this.cleanFiltered()
		this.getGrupos()
	}

	openModal(form) {
		this.modalRef = this.modalService.open(form)
		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}

	showSucessCreate(msg: any) {
		this.toastrService.success(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/grupos')
		this.getGrupos()
		this.modalRef.close()
	}

	showErrorCreate(msg: any) {
		this.toastrService.error(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/grupos')
		this.getGrupos()
		this.modalRef.close()
	}

	showSucessUpdate(msg: any) {
		this.toastrService.success(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/grupos')
		this.getGrupos()
		this.modalRef.close()
	}

	showErrorUpdate(msg: any) {
		this.toastrService.error(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/grupos')
		this.getGrupos()
		this.modalRef.close()
	}

	showSucessDelete(msg: any) {
		this.toastrService.success(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/grupos')
		this.getGrupos()
		this.modalRef.close()
	}

	showErrorDelete(msg: any) {
		this.toastrService.error(msg, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/grupos')
		this.getGrupos()
		this.modalRef.close()
	}

	showAlertInfo(msg: any) {
		this.toastrService.error(`${msg} - Ocorreu um erro ao fazer a chamada, por favor tente mais tarde!`, 'PTA - Portal de Transferência de Arquivos')
		this.router.navigateByUrl('/grupos')
		this.modalRef.close()
	}
}
