<div *ngIf="loginDisplay" class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<h4 class="title">Listar Envio de Notificações</h4>
	</div>
	<div class="card-header py-4 d-flex justify-content-end align-items-center">
		<table class="table table-striped w-50" summary="Calendario Filter">
			<thead>
				<tr>
					<th scope="col">Ano Referência</th>
					<th scope="col">Tipo Perfil</th>
				</tr>
			</thead>
			<tr>
				<td>
					<select
						[ngModel]="selectedAnoReferencia"
						class="form-select form-control border-opacity-50 form-select-sm"
						(ngModelChange)="onChangeAnoReferencia($event)"
						name="selectAnoReferencia"
					>
						<option *ngFor="let anoReferencia of anosReferenciaFilter" [value]="anoReferencia">
							{{ anoReferencia }}
						</option>
					</select>
				</td>
				<td>
					<select
						[ngModel]="selectedTipoPerfil"
						class="form-select form-control border-opacity-50 form-select-sm"
						(ngModelChange)="onChangeTipoPerfil($event)"
						name="selectTipoPerfil"
					>
						<option *ngFor="let tipoPerfil of tiposPerfilFilter" [value]="tipoPerfil">
							{{ tipoPerfil }}
						</option>
					</select>
				</td>
			</tr>
		</table>
	</div>

	<div class="card-body h-auto">
		<div class="d-flex justify-content-center align-items-center" style="height: 50vh" *ngIf="infoMessage.status == 2">
			<div class="spinner-border text-primary" role="status"></div>
		</div>
		<div class="table-responsive" *ngIf="calendarios && infoMessage.status == 3">
			<table class="table table-sm table-hover align-middle" summary="Calendario">
				<thead class="table-light">
					<tr>
						<th scope="col">Tipo Perfil</th>
						<th scope="col" class="text-center align-middle">Mês Referência</th>
						<th scope="col" class="text-center align-middle">Dia(s)/Mês Previsto Envio</th>
						<th scope="col" class="text-center align-middle">Definir</th>
						<th scope="col" class="text-center align-middle">Editar</th>
						<th scope="col" class="text-center align-middle" *ngIf="perfilTecnico || perfilNormativo">Apagar</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let calendario of calendariosFilter; let i = index">
						<td>{{ calendario.tipoPerfil }}</td>
						<td class="text-center align-middle">
							{{ calendario.mesReferenciaNotificacao }}
						</td>
						<td class="text-center align-middle" *ngIf="calendario.tipoPerfil === 'Patrocinadora'">
							{{ calendario.dataNotificacao1 }}{{ calendario.dataNotificacao2! | adicionarVirgula }}{{ calendario.dataNotificacao3! | adicionarVirgula }}
						</td>
						<td class="text-center align-middle" *ngIf="calendario.tipoPerfil === 'Consignatária'">
							{{ calendario.dataNotificacao1 }}
							<span *ngIf="calendario.mesNotificacao">/{{ calendario.mesNotificacao.substring(0, 2) }}</span>
						</td>
						<td class="text-center align-middle">
							<button type="button" class="btn btn-customer-info" (click)="create(calendario, modalCreate)" [disabled]="rulesDisabledCreate(calendario)">
								<em i-bs name="calendar-week"></em>
							</button>
						</td>
						<td class="text-center align-middle">
							<button type="button" class="btn btn-customer-info" (click)="update(calendario, modalUpdate)" [disabled]="rulesDisabledUpdate(calendario)">
								<em i-bs name="calendar-week-fill"></em>
							</button>
						</td>
						<td *ngIf="perfilTecnico" class="text-center align-middle">
							<button type="button" class="btn btn-customer-danger" (click)="delete(calendario, modalDelete)" [disabled]="rulesDisableDelete(calendario)">
								<em i-bs name="trash-fill"></em>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

	<ng-template #modalCreate let-modal>
		<div class="modal-content modal-lg">
			<div class="modal-header">
				<h4 class="title" id="modal-basic-title">Definir dias para envio de notificação</h4>
				<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
					<em i-bs name="x" width="20" height="20"></em>
				</button>
			</div>
			<div class="modal-body">
				<form class="forms-sample" [formGroup]="createCalendarForm">
					<input #dias type="hidden" id="dias" value="" />

					<div class="form-group">
						<label class="form-control-sm text-secondary" for="mesReferenciaNotificacao">Mês Referência</label>
						<input
							type="text"
							class="form-control border-opacity-50"
							formControlName="mesReferenciaNotificacao"
							id="mesReferenciaNotificacao"
							name="mesReferenciaNotificacao"
							placeholder=""
							[attr.disabled]="true"
						/>
					</div>

					<div class="form-group">
						<label class="form-control-sm text-secondary" for="tipoPerfil">Tipo Perfil</label>
						<input
							type="text"
							class="form-control border-opacity-50"
							formControlName="tipoPerfil"
							id="tipoPerfil"
							name="tipoPerfil"
							placeholder=""
							[attr.disabled]="true"
						/>
					</div>

					<div class="form-group">
						<label class="form-control-sm text-secondary" for="diaLimite">Dia limite</label>
						<div class="input-group">
							<input
								readonly
								class="form-control"
								placeholder="dd/mm/yyyy"
								name="d"
								ngbDatepicker
								#d="ngbDatepicker"
								[navigation]="navigation(createCalendarForm.value.mesReferenciaNotificacao, createCalendarForm.value.tipoPerfil)"
								[outsideDays]="outsideDays(createCalendarForm.value.mesReferenciaNotificacao, createCalendarForm.value.tipoPerfil)"
								[minDate]="minDate(createCalendarForm.value.mesReferenciaNotificacao, createCalendarForm.value.tipoPerfil)"
								[maxDate]="maxDate(createCalendarForm.value.mesReferenciaNotificacao)"
								[markDisabled]="markDisabled"
								[showWeekNumbers]="showWeekNumbers"
								(dateSelect)="calculateNotificationDays(createCalendarForm, d)"
							/>
							<button class="btn btn-input-customer" (click)="today(d)" type="button">
								<em i-bs name="calendar-range"></em>
							</button>
						</div>
					</div>
					<fieldset disabled>
						<legend style="display: none">create</legend>
						<div class="form-group d-flex" *ngIf="createCalendarForm.value.tipoPerfil === 'Consignatária'">
							<div class="form-item w-50 me-1">
								<label class="form-control-sm text-secondary" for="datasEnvio">Dias de envio Notificação</label>
								<input type="text" class="form-control border-opacity-50" formControlName="datasEnvio" id="datasEnvio" placeholder="" />
							</div>
							<div class="form-item w-50 ms-1">
								<label class="form-control-sm text-secondary" for="mesNotificacao">Mês de envio Notificação</label>
								<input type="text" class="form-control border-opacity-50" formControlName="mesNotificacao" id="mesNotificacao" placeholder="" />
							</div>
						</div>
						<div class="form-group" *ngIf="createCalendarForm.value.tipoPerfil === 'Patrocinadora'">
							<label class="form-control-sm text-secondary" for="datasEnvio">Dias de envio Notificação</label>
							<input type="text" class="form-control border-opacity-50" formControlName="datasEnvio" id="datasEnvio" placeholder="" />
						</div>
					</fieldset>
				</form>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn-modal-customer-info" (click)="onCreate()" [disabled]="disablebutton">OK</button>
				<button type="button" class="btn-modal-customer-danger" (click)="modal.dismiss('Cross click')">CANCELAR</button>
			</div>
		</div>
	</ng-template>

	<ng-template #modalUpdate let-modal>
		<div class="modal-content modal-lg">
			<div class="modal-header">
				<h4 class="title" id="modal-basic-title">Definir dias para envio de notificação</h4>
				<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
					<em i-bs name="x" width="20" height="20"></em>
				</button>
			</div>
			<div class="modal-body">
				<form class="forms-sample" [formGroup]="updateCalendarForm">
					<input #dias type="hidden" id="dias" value="" />

					<div class="form-group">
						<label class="form-control-sm text-secondary" for="mesReferenciaNotificacao">Mês Referência</label>
						<input
							type="text"
							class="form-control border-opacity-50"
							formControlName="mesReferenciaNotificacao"
							id="mesReferenciaNotificacao"
							name="mesReferenciaNotificacao"
							placeholder=""
							[attr.disabled]="true"
						/>
					</div>

					<div class="form-group">
						<label class="form-control-sm text-secondary" for="tipoPerfil">Tipo Perfil</label>
						<input
							type="text"
							class="form-control border-opacity-50"
							formControlName="tipoPerfil"
							id="tipoPerfil"
							name="tipoPerfil"
							placeholder=""
							[attr.disabled]="true"
						/>
					</div>

					<div class="form-group">
						<label class="form-control-sm text-secondary" for="diaLimite">Dia limite</label>
						<div class="input-group">
							<input
								readonly
								class="form-control"
								placeholder="dd/mm/yyyy"
								name="d"
								ngbDatepicker
								#d="ngbDatepicker"
								[navigation]="navigation(updateCalendarForm.value.mesReferenciaNotificacao, updateCalendarForm.value.tipoPerfil)"
								[outsideDays]="outsideDays(updateCalendarForm.value.mesReferenciaNotificacao, updateCalendarForm.value.tipoPerfil)"
								[minDate]="minDate(updateCalendarForm.value.mesReferenciaNotificacao, updateCalendarForm.value.tipoPerfil)"
								[maxDate]="maxDate(updateCalendarForm.value.mesReferenciaNotificacao)"
								[markDisabled]="markDisabled"
								[showWeekNumbers]="showWeekNumbers"
								(dateSelect)="calculateNotificationDays(updateCalendarForm, d)"
							/>
							<button class="btn btn-input-customer" (click)="today(d)" type="button">
								<em i-bs name="calendar-range"></em>
							</button>
						</div>
					</div>
					<fieldset disabled>
						<legend style="display: none">Upload</legend>
						<div class="form-group d-flex" *ngIf="updateCalendarForm.value.tipoPerfil === 'Consignatária'">
							<div class="form-item w-50 me-1">
								<label class="form-control-sm text-secondary" for="dataNotificacao1">Dias de envio Notificação</label>
								<input type="text" class="form-control border-opacity-50" formControlName="dataNotificacao1" id="dataNotificacao1" placeholder="" />
							</div>
							<div class="form-item w-50 ms-1">
								<label class="form-control-sm text-secondary" for="mesNotificacao">Mês de envio Notificação</label>
								<input type="text" class="form-control border-opacity-50" formControlName="mesNotificacao" id="mesNotificacao" placeholder="" />
							</div>
						</div>
						<div class="form-group" *ngIf="updateCalendarForm.value.tipoPerfil === 'Patrocinadora'">
							<label class="form-control-sm text-secondary" for="dataNotificacao1">Dias de envio Notificação</label>
							<input type="text" class="form-control border-opacity-50" formControlName="dataNotificacao1" id="dataNotificacao1" placeholder="" />
						</div>
					</fieldset>
				</form>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn-modal-customer-info" (click)="onUpdate()" [disabled]="disablebutton">OK</button>
				<button type="button" class="btn-modal-customer-danger" (click)="modal.dismiss('Cross click')">CANCELAR</button>
			</div>
		</div>
	</ng-template>

	<ng-template #modalDelete let-modal>
		<div class="modal-header">
			<h4 class="title" id="modal-basic-title">Apagar Notificação</h4>
			<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
				<em i-bs name="x" width="20" height="20"></em>
			</button>
		</div>
		<div class="modal-body">
			<fieldset>
				<legend>Deseja realmente excluir esse registro ?</legend>
			</fieldset>
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn-modal-customer-info" (click)="onDelete(deleteCalendarForm)">OK</button>
			<button type="button" class="btn-modal-customer-danger" (click)="modal.dismiss('Cross click')">CANCELAR</button>
		</div>
	</ng-template>
</div>
