<div *ngIf="loginDisplay" class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<h4 class="title">Arquivos Enviados</h4>
		<div class="input-group search w-25" style="margin-right: 0">
			<input class="form-control" type="text" placeholder="Pesquisar" [(ngModel)]="listFilter" />
			<div class="input-group-append">
				<button type="button" class="btn-input-customer" arial-label="Close" (click)="cleanFiltered()">
					<em i-bs name="x"></em>
				</button>
			</div>
		</div>
	</div>
	<div class="card-body h-auto">
		<div class="d-flex justify-content-center align-items-center py-5" *ngIf="infoMessage.status == 1">
			<strong>{{ infoMessage.message }}</strong>
		</div>

		<div class="d-flex justify-content-center align-items-center py-5" *ngIf="infoMessage.status == 2">
			<div class="spinner-border text-primary" role="status"></div>
		</div>

		<div class="d-flex justify-content-center align-items-center py-5" *ngIf="(usuario?.grupo)!.empresas.length == 0">
			<strong>"O grupo que o usuário está inserido não há arquivos enviados!"</strong>
		</div>

		<div class="table-responsive" *ngIf="registroAtividades && infoMessage.status == 3">
			<table class="table table-sm table-hover align-middle" summary="Arquivos Enviados">
				<thead class="table-light">
					<tr>
						<th scope="col" class="align-middle">Nome Arquivo</th>
						<th scope="col" class="align-middle">Processo</th>
						<th scope="col" class="align-middle text-center">Grupo</th>
						<th scope="col" class="align-middle text-center">Usuário</th>
						<th scope="col" class="text-center align-middle">Data</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let registro of pagefiltered; let i = index">
						<td class="align-middle">
							<ngb-highlight [result]="registro.nomeArquivoEnviado" [term]="filter.value!" title="{{ registro.nomeArquivoEnviado }}"></ngb-highlight>
						</td>
						<td class="align-middle">
							<ngb-highlight [result]="registro.nomeProcesso | lowercase" [term]="filter.value!"></ngb-highlight>
						</td>
						<td class="align-middle text-center">
							<ngb-highlight [result]="registro.nomeGrupo" [term]="filter.value!"></ngb-highlight>
						</td>
						<td class="align-middle text-center">
							<ngb-highlight [result]="userFormated(registro)" [term]="filter.value!"></ngb-highlight>
						</td>
						<td class="align-middle text-center">
							<ngb-highlight [result]="formatedData(registro)" [term]="filter.value!"></ngb-highlight>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card-footer-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<ngb-pagination
			class="pagination-customer"
			[collectionSize]="collectionSize"
			[(page)]="page"
			[pageSize]="pageSize"
			(pageChange)="refreshPage()"
		></ngb-pagination>

		<select class="pagination-select-customer" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshPage()">
			<option [ngValue]="100">100 items por página</option>
			<option [ngValue]="200">200 items por página</option>
			<option [ngValue]="300">300 items por página</option>
		</select>
	</div>
</div>
